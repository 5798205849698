import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_DEALS = '[DEALS APP] GET DEALS';
export const GET_DEALS_ERROR = '[DEALS APP] GET DEALS ERROR';
export const CLEAR_DEALS = '[DEALS APP] CLEAR';

export function getDeals() {

    return (dispatch) => {
        dispatch({
            type: GET_DEALS_ERROR,
            payload: false
        });

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/deal?_embed`)
            .then((res) => {
                dispatch({
                    type: GET_DEALS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: GET_DEALS_ERROR,
                    payload: true
                });
            })
    }
}

export function clearDeals() {

    return (dispatch) => dispatch({ type: CLEAR_DEALS });
}
