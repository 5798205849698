import React, { useEffect, useState } from "react";
import {Alert, Button, Card, Col, Container, Image, Form, InputGroup, Row} from "react-bootstrap";
import withReducer from "../../../../../store/withReducer";
import reducer from "./store/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import get from "lodash/get";
import ProfileInfo from "../../../../components/ProfileInfo";
import ProfileGroups from "../../../../components/ProfileGroups";
import Loader from "../../../../../components/Loader";
import { Link, navigate } from "@reach/router"
import SendMessage from "./components/SendMessage";
import SideMenu from "../../../Profile/components/SideMenu";

const Member = (props) => {
    const dispatch = useDispatch();

    const data = useSelector(({ memberApp }) => memberApp.member.data);
    const xprofile = useSelector(({ memberApp }) => memberApp.member.xprofile);
    const error = useSelector(({ memberApp }) => memberApp.member.error);
    const load = useSelector(({ memberApp }) => memberApp.member.load);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        dispatch(Actions.getMember({
            id: props.ID
        }));

        return () => {
            dispatch(Actions.clearMember());
        }
    }, [dispatch, props.ID]);

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                        <Container fluid className="py-2 my-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/members/browse">Members</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        {get(data, 'name')}
                                    </li>
                                </ol>
                            </nav>
                        </Container>
                    </section>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content">
                            <Row className="no-gutters d-flex">
                                {data && (
                                    <Col xs={ 12 } className="px-3 mb-3">
                                        <button
                                            className="btn button btn-outline-primary"
                                            onClick={handleShow}
                                        >
                                            Send message
                                        </button>
                                    </Col>
                                )}
                                <Col xs={ 12 } className="px-3 mb-3">
                                    {
                                        error && (
                                            <Col xs={ 12 } className="mt-4">
                                                <Alert variant="danger">
                                                    Error Member
                                                </Alert>
                                            </Col>
                                        )
                                    }
                                    {
                                        data && (
                                            <Col xs={ 12 }>
                                                <div className="d-flex justify-content-center flex-column">
                                                    { get(data, 'avatar') && (
                                                        <Image
                                                            src={get(data, 'avatar')}
                                                            style={{"maxHeight":"250px","objectFit":"contain"}}
                                                        />
                                                    )
                                                    }
                                                    <h4 className="text-center mt-3 font-weight-bold">
                                                        { get(data, 'name') }
                                                    </h4>
                                                </div>
                                            </Col>
                                        )
                                    }
                                </Col>
                                <Col xs={ 12 } md={8} className="px-3 mb-3">
                                    <Row>
                                        {
                                            xprofile && (
                                                <ProfileGroups
                                                    fields={ xprofile }
                                                    view
                                                />
                                            )
                                        }
                                        {
                                            load && ( <Loader/> )
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </main>
                </Row>
            </Container>
            <SendMessage name={get(data, 'name')} show={show} handleClose={handleClose} />
        </>
    )
};

export default withReducer('memberApp', reducer)(Member);