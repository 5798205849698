import axios from 'axios';
import qs from 'qs';
import { authWithToken } from "../../../../../store/actions";
import * as CONSTANTS from "../../../../../constants";

export const GET_VIDEOS = '[VIDEOS APP] GET VIDEOS';
export const GET_VIDEOS_ERROR = '[VIDEOS APP] GET VIDEOS ERROR';
export const CLEAR_VIDEOS = '[VIDEOS APP] CLEAR';

export function setStatusVideo({ video_id, action, field_name }) {

    return (dispatch) =>
        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/course`, qs.stringify({
            video_id,
            action,
            field_name,
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(() => {
                return dispatch(authWithToken());
            })
}

export function getVideos() {

    return (dispatch) => {
        dispatch({
            type: GET_VIDEOS_ERROR,
            payload: false
        });

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/video?_embed`)
            .then((res) => {
                dispatch({
                    type: GET_VIDEOS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: GET_VIDEOS_ERROR,
                    payload: true
                });
            })
    }
}

export function clearVideos() {

    return (dispatch) => dispatch({ type: CLEAR_VIDEOS });
}