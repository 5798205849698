import axios from 'axios';
import get from 'lodash/get';

export const GET_COMPANY = '[COMPANY APP] GET COMPANY';
export const GET_COMPANY_INFO = '[COMPANY APP] GET COMPANY INFO';
export const GET_COMPANY_ERROR = '[COMPANY APP] GET COMPANY ERROR';
export const CLEAR_COMPANY = '[COMPANY APP] CLEAR';
export const CLEAR_COMPANY_INFO = '[COMPANY APP] CLEAR INFO';

export function getCompanyByName({ name, per_page = 0 }) {

    return (dispatch) => {
        dispatch({
            type: GET_COMPANY_ERROR,
            payload: false
        });

        return axios.get(`https://api.companieshouse.gov.uk/search/companies`, {
            auth: {
                username: "OMFvMcPb-fAOjAtF_qSoAj74-iAGtTgdyBa7I55c",
                password: ""
            },
            params: {
                q: name,
                items_per_page: per_page,
            }
        })
            .then((response) => {
                dispatch({
                    type: GET_COMPANY,
                    payload: get(response.data, 'items') || []
                });

                if (!response.data) {
                    dispatch({
                        type: GET_COMPANY_ERROR,
                        payload: true
                    })
                }
            })
            .catch(() => {
                dispatch({
                    type: GET_COMPANY_ERROR,
                    payload: true
                });
                dispatch({
                    type: GET_COMPANY,
                    payload: null
                });
            })
    }
}

export function getCompanyInfo({ number }) {

    return (dispatch) => {

        dispatch({
            type: GET_COMPANY_INFO,
            payload: {
                name: '123',
                number: '21345213',
            }
        });
    }
}

export function clearCompany() {

    return (dispatch) => dispatch({ type: CLEAR_COMPANY });
}

export function clearCompanyInfo() {

    return (dispatch) => dispatch({ type: CLEAR_COMPANY_INFO });
}
