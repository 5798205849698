import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import ProfileListingManage from "./ProfileListingManage";

const Listing = () => {

    const dispatch = useDispatch();
    const xprofile = useSelector(({ profileApp }) => profileApp.profile.xprofile);
    const active_subscriptions = useSelector(({ profileApp }) => profileApp.profile.active_subscriptions);

    useEffect(() => {
        dispatch(Actions.getUserXprofileData());
        dispatch(Actions.getUserMainData());
        dispatch(Actions.getUserMe());
    }, [dispatch]);

    return (
        <div className="App">
            <Container>
                <Row>
                    <Col xs={ 12 }>
                        <h1 className="mt-4 mb-4">Listing</h1>
                    </Col>
                    <ProfileListingManage
                        fields={ xprofile }
                        active_subscriptions={ active_subscriptions }
                    />
                </Row>
            </Container>
        </div>
    );
};

export default withReducer('profileApp', reducer)(Listing);
