import axios from "axios";
import * as CONSTANTS from "../../constants";

export const GET_USER_AUTH = '[AUTH] GET USER';
export const LOGOUT_AUTH = '[AUTH] LOGOUT USER';

export function authWithToken() {

    return (dispatch) =>
        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/me`)
            .then((res) => {
                dispatch({
                    type: GET_USER_AUTH,
                    payload: res.data
                });
            });
}

export function logout() {

    return (dispatch) => {
        localStorage.removeItem('access_token');
        dispatch({ type: LOGOUT_AUTH });
    }
}