import axios from 'axios';
import * as CONSTANTS from "../../../../constants";

export const PASSWORD_FORGOT_ERROR = '[PASSWORD FORGOT APP] PASSWORD FORGOT ERROR';
export const PASSWORD_FORGOT_SUCCESS = '[PASSWORD FORGOT APP] PASSWORD FORGOT SUCCESS';
export const CLEAR = '[PASSWORD FORGOT APP] CLEAR';

export function sendPasswordForgot({ user_login }) {

    return (dispatch) => {
        dispatch(clear());

        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/lostpassword`, {
            user_login
        })
            .then((response) => {
                dispatch({
                    type: PASSWORD_FORGOT_SUCCESS,
                    payload: response.data.message
                })
            })
            .catch((error) => {
                dispatch({
                    type: PASSWORD_FORGOT_ERROR,
                    payload: error.response.data.message
                })
            })
    }
}

export function clear() {

    return (dispatch) => {
        dispatch({
            type: CLEAR
        });
    }
}