import React from "react";
import { Link } from "@reach/router"
import get from 'lodash/get';
import PostStatus from "../../../../components/PostStatus";
import { is_state, SITE_URL } from "../../../../constants";
import * as GeneralActions from "../../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import * as CONSTANTS from "../../../../constants";

const ProfileLessonItem = ({ item }) => {

    const dispatch = useDispatch();
    const user_acf = useSelector(({ auth }) => auth.user.acf);
    const status_complete = is_state(item?.id, user_acf, 'lesson_complete');

    function title() {
        return {__html: get(item, 'title.rendered')};
    }

    const renderTitle = () => (
        <h3 className="text-left mb-3 d-block w-100 h5" dangerouslySetInnerHTML={title()} style={{
            textDecoration: status_complete ? 'line-through' : '',
        }}/>
    );

    function content() {
        return {__html: get(item, 'acf.short_description')};
    }

    const renderContent = () => (
        <p className="text-left w-100" dangerouslySetInnerHTML={content()}/>
    );

    return (
        <li
            className={"course__card border mb-2 mb-md-4 "}
            style={{
                backgroundColor: status_complete ? '#f8f9fa' : '',
            }}>
            <div className="row">
                <div className=" col-12 col-md-3">
                    <Link to={ `/lesson/${ item.id }` } className="d-block h-100">
                        <img
                            className="h-100 w-100" style={ { objectFit: 'cover' } }
                            src={ `${ get(item, '_embedded.wp:featuredmedia[0].source_url') ? get(item, '_embedded.wp:featuredmedia[0].source_url') : CONSTANTS.SITE_URL + '/app/uploads/2020/09/ssfs-default-video.jpg' }` }
                            alt=""
                        />
                    </Link>
                </div>
                <div className="col-12 col-md-6">
                    <div className="p-md-4 p-2">
                        <h4 className="mb-4">
                            <Link className="text-primary" to={ `/lesson/${ item.id }` }>
                               {renderTitle()}
                            </Link>
                        </h4>
                        {!status_complete && renderContent()}
                    </div>
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center flex-column post_status">
                    <PostStatus
                        item_id={ item.id  }
                        is_complete_state={ (param) => is_state(param, user_acf, 'lesson_complete') }
                        is_skipped_state={ (param) => is_state(param, user_acf, 'lesson_skipped') }
                        setStatusVideo={ async (payload) => {
                            await dispatch(GeneralActions.setStatusVideo({
                                ...payload,
                                post_type: 'lesson'
                            }));
                        } }
                        type="lesson"
                    />
                </div>
            </div>
        </li>
    )
};

export default ProfileLessonItem;