import axios from 'axios';
import * as CONSTANTS from '../../constants';

export const GET_COMMENTS = '[COMMENTS APP] GET COMMENTS';
export const GET_COMMENTS_PARENT = '[COMMENTS APP] GET COMMENTS PARENT';
export const SET_LOAD_COMMENTS = '[COMMENTS APP] SET LOAD COMMENTS';
export const SET_TOTAL_COMMENTS = '[COMMENTS APP] SET TOTAL COMMENTS';
export const CLEAR_COMMENTS = '[COMMENTS APP] CLEAR';

export function getComments({ id, per_page = 10, load = true, isParent = false, parent_id = 0 }) {

    return (dispatch) => {
        if (load) {
            dispatch(setLoadComments(true));
        }

        const newParams = {
            post: id,
            per_page,
        }

        if (isParent) {
            newParams.parent = parent_id;
        }

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/comments`, {
            params: newParams
        })
            .then((response) => {
                if (parent_id) {
                    dispatch({
                        type: GET_COMMENTS_PARENT,
                        payload: {
                            [parent_id]: response.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_COMMENTS,
                        payload: response.data
                    });
                    dispatch({
                        type: SET_TOTAL_COMMENTS,
                        payload: Number(response.headers['x-wp-total']),
                    });
                }
                dispatch(setLoadComments(false));
            })
            .catch(() => {
                dispatch(setLoadComments(false));
            })
    }
}

export function clearComments() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_COMMENTS
        });
    }
}

export function setLoadComments(isLoad) {

    return (dispatch) => {
        dispatch({
            type: SET_LOAD_COMMENTS,
            payload: isLoad
        });
    }
}
