import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_WEEKS = '[WEEKNUMBER PAGE] GET WEEKS';

export const getWeeks = () => async (dispatch, getState) => {

    // const { id } = getState().auth.user;
    const { acf } = getState().auth.user;

    try {
        const res = await axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/user_report/${acf.user_report}?_fields=acf,id&skip_cache=1`);

        // console.log(res);
        dispatch({
            type: GET_WEEKS,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
    
  }