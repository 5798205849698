import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../store/actions';
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "@reach/router";
import '../style/comments.css';
import Comment from "./Comment";

const CommentList = ({ id }) => {

    const dispatch = useDispatch();

    const [ per_page, set_per_page ] = useState(10);

    const data = useSelector(({ comments }) => comments.data);
    const load = useSelector(({ comments }) => comments.load);
    const total = useSelector(({ comments }) => comments.total);

    useEffect(() => {
        dispatch(Actions.getComments({ id, isParent: true }));

        return () => {
            dispatch(Actions.clearComments());
        }
    }, [ dispatch, id ]);

    const loadMore = () => {
        const new_per_page = per_page + 10;

        dispatch(Actions.getComments({
            id,
            per_page: new_per_page,
            load: false,
            isParent: true
        }))
            .then(() => set_per_page(new_per_page))
    };

    return (
        <Row className="comment-list">
            {
                load
                    ? <Col xs={ 12 }>Loading...</Col>
                    : (
                        <>
                            {
                                Boolean(data.length)
                                    ? (
                                        <>
                                            {
                                                data.map((comment) => (
                                                    <Comment
                                                        id={ comment.id }
                                                        author_name={ comment.username }
                                                        comment={ comment.content.rendered }
                                                        key={ comment.id }
                                                        has_children={comment.has_children}
                                                        children_count={comment.children_count}
                                                        username={ comment.username.split('@')[0] }
                                                        post_id={ id }
                                                    />
                                                ))
                                            }
                                            {
                                                ((data.length >= 10) && (total > data.length)) && (
                                                    <Col xs={ 12 } className="mt-2 text-center">
                                                        <Button
                                                            onClick={ loadMore }
                                                        >
                                                            Load more
                                                        </Button>
                                                    </Col>
                                                )
                                            }
                                        </>
                                    )
                                    : (
                                        <Col xs={ 12 }>There are no comments.</Col>
                                    )
                            }
                        </>
                    )
            }
        </Row>
    )
};

export default CommentList;