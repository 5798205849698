import React from 'react';
import { Link } from "@reach/router";

export const Button = ({isLink, isOutbound, isSimple, children, className, ...props}) => (
  <>
    {isLink && !isOutbound &&
      <Link
        className={isSimple ? `button button__link--simple ${className}` : `button button__link ${className}`}
        {...props}>
        {children}
      </Link>}
    {isLink && isOutbound &&
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className={isSimple ? `button button__link--simple ${className}` : `button button__link ${className}`}
      {...props}>
      {children}
    </Link>}
    {!isLink && !isOutbound && <button
        className={isSimple ? `button button__normal--simple ${className}` : `button button__normal ${className}`}
        {...props}>
        {children}
      </button>}
  </>
);
