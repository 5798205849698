import React, { useState, useEffect } from "react";
import {IoMdMenu, IoMdClose} from 'react-icons/io';
import {Button} from './Button';
import {useAttributeClass} from '../helpers/hooks/themeHooks';

export const MenuSwitch = () => {

    const [localMenu, setLocalMenu] = useState('mobileMenu-close');

    useAttributeClass(localMenu);
    return <Button
        isSimple
        className="mobileMenu-switch__button text-secondary"
        title="Switch Menu"
        onClick={() => setLocalMenu(localMenu === 'mobileMenu-close' ? 'mobileMenu-open' : 'mobileMenu-close')}>
        {localMenu === 'mobileMenu-open' ? <IoMdClose className="d-block" size={48}/> : <IoMdMenu className="d-block" size={48}/>}
        <span>Menu</span>
    </Button>;
};
