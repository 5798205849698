import React, { useEffect } from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import ProfileLessonItem from "./ProfileLessonItem";

const ProfileLessons = () => {

    const dispatch = useDispatch();

    const error = useSelector(({ profileApp }) => profileApp.lessons.error);
    const data = useSelector(({ profileApp }) => profileApp.lessons.data);
    const user = useSelector(({ auth }) => auth.user);

    useEffect(() => {
        if (user) {
            dispatch(Actions.getLessons());
        }

        return () => {
            dispatch(Actions.clearLessons());
        }
    }, [user, dispatch]);

    const renderLessonItem = (item) => (
        <>
            <ProfileLessonItem
                item={item}
                key={item.id}
            />
        </>
    );

    return (
        <>
            <Container>
                <Row className="no-gutters d-flex">
                    <Col xs={ 12 }>
                        <h3 className="text-left mb-4 h4">Sessions</h3>
                    </Col>
                </Row>
                <Row className="no-gutters d-flex">
                    <Col xs={ 12 }>
                        <ul className="list-unstyled p-0">
                        {
                            error && (
                                <Alert variant="danger" className="mt-4 mb-0">
                                    Error Sessions
                                </Alert>
                            )
                        }
                        {
                            !Boolean(data.length) && (
                                <Alert variant="info" className="mt-4 mb-0">
                                    No sessions have been released yet.
                                </Alert>
                            )
                        }
                        {
                            Boolean(data.length) && data.map((item, i) => {
                                return renderLessonItem(item);
                            })
                        }
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    )

};

export default ProfileLessons;