import { GET_UPDATE_DATA } from '../actions';

export default (state = {data: []}, { type, payload }) => {
    switch(type) {
        case GET_UPDATE_DATA: {
            return {
                ...state,
                data: payload
            }
        }
        default:
            return state
    }
}