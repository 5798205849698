import * as Actions from '../actions';

const initialState = {
    data: [],
    load: false,
    parents: {},
    total: 100,
};

const commentsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMMENTS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.SET_LOAD_COMMENTS: {
            return {
                ...state,
                load: action.payload
            };
        }
        case Actions.SET_TOTAL_COMMENTS: {
            return {
                ...state,
                total: action.payload
            };
        }
        case Actions.GET_COMMENTS_PARENT: {
            return {
                ...state,
                parents: {
                    ...state.parents,
                    ...action.payload
                }
            };
        }
        case Actions.CLEAR_COMMENTS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default commentsReducer;
