import * as Actions from '../actions';

const initialState = {
    xprofile: null,
    main_data: null,
    avatar: null,
    active_subscriptions: null,
    upload_error: false,
    upload_success: false,
    cohort: {}
};

const profileReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COHORT: {
            return {
                ...state,
                cohort: action.payload
            }
        }
        case Actions.CLEAR_COHORT: {
            return initialState
        }
        case Actions.GET_USER_XPROFILE_DATA: {
            return {
                ...state,
                xprofile: action.payload
            };
        }
        case Actions.GET_USER_MAIN_DATA: {
            return {
                ...state,
                main_data: action.payload
            };
        }
        case Actions.SET_LOAD_XPROFILE_DATA: {
            return {
                ...state,
                load: action.payload
            };
        }
        case Actions.GET_USER_ME: {
            return {
                ...state,
                avatar: action.payload.avatar,
                active_subscriptions: action.payload.active_subscriptions
            };
        }
        case Actions.UPLOAD_AVATAR_ERROR: {
            return {
                ...state,
                upload_error: action.payload
            };
        }
        case Actions.UPLOAD_AVATAR_SUCCESS: {
            return {
                ...state,
                upload_success: action.payload
            };
        }
        case Actions.CLEAR: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default profileReducer;
