import React from 'react';
import * as styles from "./Video.module.scss"

const Video = ({fileMp4, fileWebm}) => {

        return (
            <video className={styles.videoFrame} controls>
                <source src={fileMp4} type="video/mp4" />
                <source src={fileWebm} type="video/webm" />
            </video>
        );
};

export { Video };
