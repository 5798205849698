import axios from 'axios';
import * as userActions from '../../../../store/actions';
import * as CONSTANTS from "../../../../constants";

export const REGISTER_USER_ERROR = '[REGISTER APP] REGISTER USER ERROR';
export const SET_REGISTER_LOAD = '[REGISTER APP] SET REGISTER LOAD';
export const CLEAR = '[REGISTER APP] CLEAR';

export function registerUser({ username, password, email, code }) {

    return (dispatch) => {
        dispatch(clear());
        dispatch(setRegisterLoad(true));

        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/register`, {
            username, password, email, code
        })
            .then((response) => {
                axios.defaults.headers['Authorization'] = `Bearer ${ response.data.token }`;
                localStorage.setItem('access_token', response.data.token);

                dispatch(userActions.authWithToken());
                dispatch(setRegisterLoad(false));

            })
            .catch((error) => {
                dispatch(setRegisterLoad(false));

                if (error.response.data.code === 406) {
                    return dispatch({
                        type: REGISTER_USER_ERROR,
                        payload: 'Username or email is already in use. Please try to <a href="/login/">login instead</Link>.'
                    })
                }

                dispatch({
                    type: REGISTER_USER_ERROR,
                    payload: error.response.data.message
                })
            })
    }
}

export function setRegisterLoad(payload) {

    return (dispatch) => {
        dispatch({
            type: SET_REGISTER_LOAD,
            payload,
        });
    }
}

export function clear() {

    return (dispatch) => {
        dispatch({
            type: CLEAR
        });
    }
}
