import * as Actions from '../actions';

const initialState = {
    data: null,
    messages: [],
    error: false,
    load: false,
    loadDialog: false,
};

const userDialogReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USER_DIALOG: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_USER_MESSAGES: {
            return {
                ...state,
                messages: action.payload
            };
        }
        case Actions.ERROR_USER_DIALOG: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.LOAD_DIALOG: {
            return {
                ...state,
                loadDialog: action.payload
            };
        }
        case Actions.LOAD_USER_DIALOG: {
            return {
                ...state,
                load: action.payload
            };
        }
        case Actions.CLEAR_USER_DIALOG: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default userDialogReducer;
