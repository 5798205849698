import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

const ProfileListingCompanyForm = ({ set_step }) => {

    // eslint-disable-next-line
    const dispatch = useDispatch();

    const [form, setForm] = useState({
        marketing: false,
        web_development: false,
        reviews_url: '',
        goals: '',
        copyright: false,
    });

    const handlerForm = ({ target }) => {

        if (target.type === 'checkbox') {
            return setForm((oldForm) => ({
                ...oldForm,
                [target.name]: target.checked
            }))
        }

        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }));
    };

    return (
        <>
            <Col xs={ 12 }>
                <div className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        onClick={ () => set_step(1) }
                    >
                        Back
                    </Button>
                    <Button
                        onClick={ () => {
                            alert('Submit');
                        } }
                    >
                        Send
                    </Button>
                </div>
            </Col>
            <Col xs={ 6 } className="mt-4">
                <Form>
                    <Form.Group>
                        <Form.Label>
                            Services
                        </Form.Label>
                        <Form.Check
                            type="checkbox"
                            checked={ form.marketing }
                            onChange={ handlerForm }
                            id="marketing"
                            name="marketing"
                            label="Marketing"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={ form.web_development }
                            onChange={ handlerForm }
                            id="web_development"
                            name="web_development"
                            label="Web Development"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Reviews URL
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={ form.reviews_url }
                            name="reviews_url"
                            onChange={ handlerForm }
                        />
                        <span className="mt-1 small">Where can we find your reviews?</span>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Goals
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="5"
                            value={ form.goals }
                            name="goals"
                            onChange={ handlerForm }
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Copyright
                        </Form.Label>
                        <Form.Check
                            type="checkbox"
                            checked={ form.copyright }
                            onChange={ handlerForm }
                            id="copyright"
                            name="copyright"
                            label="Can we show your (persona) profile image and name on your business listing?"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            What do you need support with the most?
                        </Form.Label>
                        <Form.Check
                            type="checkbox"
                            checked={ form.marketing }
                            onChange={ handlerForm }
                            id="marketing"
                            name="marketing"
                            label="Marketing"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={ form.web_development }
                            onChange={ handlerForm }
                            id="web_development"
                            name="web_development"
                            label="Web Development"
                        />
                    </Form.Group>
                </Form>
            </Col>
        </>
    )
};

export default ProfileListingCompanyForm;
