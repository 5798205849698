import React from "react";
import { Card, Col } from "react-bootstrap";
import get from 'lodash/get';
import {RiMapPin2Fill} from 'react-icons/ri';
import find from "lodash/find";
import values from "lodash/values";
import { navigate } from "@reach/router"

function strip(html){
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const MembersList = ({ data }) => {

    return (
        <div className="members px-6">
            {
                data.map((item) => {

                    const extended_fields = get(find(get(item, 'xprofile.groups'), {name: "General"}), 'fields');
                    const location = get(find(values(extended_fields), {name: 'Location (City, Country)'}), 'value.raw');
                    // const interests = get(find(values(extended_fields), {name: 'Interests'}), 'value.raw');
                    const bio = get(find(values(extended_fields), {name: 'About me'}), 'value.rendered');

                    return (
                        <div className="card mb-3 col-12 col-md-10 cursor-pointer"
                             key={item.id}
                             onClick={() => navigate(`/members/${item.id}`)}
                        >
                            <div className="row no-gutters">
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title mb-2"> {get(item, 'name').split('@')[0]}</h5>
                                        {
                                            location && (
                                                <p className="card__location font-weight-bold small text-primary d-flex align-items-center">
                                                    <span className="pr-1">
                                                        <RiMapPin2Fill/>
                                                    </span>
                                                    { location }
                                                </p>
                                            )
                                        }
                                        {
                                            bio && (
                                                <p className="card-text">
                                                    { strip(bio) }
                                                </p>
                                            )
                                        }
                                        <p className="card-text">
                                            <small className="text-muted">
                                             Joined:&nbsp;{get(item, 'registration_date')}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="card-footer d-flex align-items-center justify-content-left justify-content-md-end h-100 pb-3 pb-md-0">
                                        <span className="card__button btn btn-primary">View member</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default MembersList;