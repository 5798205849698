import React from "react";
import { Link } from "@reach/router"
import get from 'lodash/get';
import PostStatus from "../../../../components/PostStatus";
import * as CONSTANTS from "../../../../constants";

const ProfileVideosItem = ({ item, is_complete_state, is_skipped_state, setStatusVideo, type }) => {
    return (
        <div
            className={
                (is_skipped_state(item.id)  === true ? " video__card border p-4 video__skipped" : "video__card border p-4")
            }
            >
            <div className="row">
                <div className="col-12 col-md-3">
                    <Link to={ `/video/${ item.id }` } className="video-item__image mt-2">
                        <img
                            className="h-100 w-100" style={ { objectFit: 'cover' } }
                            src={ `${ get(item, 'acf.video_link.thumbnail') ? get(item, 'acf.video_link.thumbnail') : CONSTANTS.SITE_URL + `/app/uploads/2020/09/ssfs-default-video.jpg` }` }
                            alt=""
                        />
                    </Link>
                </div>
                <div className="col-12 col-md-6">
                    <h4 className="mb-3">
                        <Link className="text-primary" to={ `/videos/${ item.id }` }>
                            { get(item, 'title.rendered') }
                        </Link>
                    </h4>
                    <p>
                    </p>
                    <ul>
                        <li>Praesent rhoncus ornare augue,</li>
                        <li>Eget mollis magna porttitor vitae.</li>
                        <li>Morbi interdum tempus fermentum.</li>
                    </ul>
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center justify-content-center flex-column">
                    <PostStatus
                        item_id={ item.id }
                        is_complete_state={ is_complete_state }
                        is_skipped_state={ is_skipped_state }
                        setStatusVideo={ setStatusVideo }
                        type={ type }
                    />
                </div>
            </div>
        </div>
    )
};

export default ProfileVideosItem;