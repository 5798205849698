import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Col, Container, Row} from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import getCorrectDateFormat from '../../../utils/getCorrectDateFormat'
import { getUpdateAction }from '../store/actions';
import SideMenu from "./SideMenu";
import { Link } from "@reach/router";
import * as Actions from "../store/actions";



function PreviousUpdates() {
    const dispatch = useDispatch();
    const data = useSelector(({ updateReducer }) => updateReducer.data);
    const isWeekInApi = useSelector(({ weekReducer }) => weekReducer.isWeekInApi);

    useEffect(() => {
        dispatch(getUpdateAction());
        dispatch(Actions.getWeeks());
    }, [])

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                        <Container className="py-2 my-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        My Previous Updates
                                    </li>
                                </ol>
                            </nav>
                        </Container>
                    </section>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content">
                            <div className="container">
                                <div className='update mb-3'>
                                    {isWeekInApi ?
                                        <>
                                            <div className="alert alert-primary clearfix" role="alert">
                                                You have submitted this weeks update. Good job!
                                            </div>
                                        </> :
                                        <>
                                            <div className="alert alert-warning d-flex align-items-center" role="alert">
                                                <p className="m-0 mr-3">You havent submitted this weeks update yet.</p>
                                                <Link
                                                    className="ml-auto btn btn-primary"
                                                    to={'/updates'}>
                                                    Send in your update
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                                {data.map((el) => (
                                    <Row className="no-gutters d-flex mb-3">
                                        <div className='update'>
                                            <div className='updateContainer'>
                                                <div className='updateContainer-wrapper'>
                                                    <div className='wrapper-header'>
                                                        <div className='header-container'>
                                                            <div className='header_date'>
                                                                <span>{getCorrectDateFormat(el.date_created, el.date_updated)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='wrapper-title'>
                                                        <div className='title-container'>
                                                            <div className='title-wrapper'>
                                                                <div className='title_wau'>
                                                                    <span>Sales made</span>
                                                                </div>
                                                                <div className='title_morale'>
                                                                    <span>Morale</span>
                                                                </div>
                                                                <div className='title_users'>
                                                                    <span>Users talk to</span>
                                                                </div>
                                                            </div>
                                                            <div className='title-wrapper'>
                                                                <div className='title_wau'>
                                                                    <span>{el[9]}</span>
                                                                </div>
                                                                <div className='title_morale'>
                                                                    <span>{el[6]}/10</span>
                                                                </div>
                                                                <div className='title_users'>
                                                                    <span>{el[4]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='wrapper-body'>
                                                        <div className='body-container'>
                                                            <div className='body-goals'>
                                                                <p>What are your top 3 goals for this week?</p>
                                                                <p>{el[7]}</p>
                                                            </div>
                                                            <div className='body-learned'>
                                                                <p>What is your biggest challenge?</p>
                                                                <p>{el[8]}</p>
                                                            </div>
                                                            <div className='body-learned'>
                                                                <p>What have you learned from your users?</p>
                                                                <p>{el[5]}</p>
                                                            </div>
                                                            <div className='body-metric'>
                                                                <p>What did you learn from your homework this week?</p>
                                                                <p>{el[10]}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    </main>
                </Row>
            </Container>
        </>
    )
};

export default PreviousUpdates;
