import axios from 'axios';
import * as CONSTANTS from "../../../../../../../constants";

export const GET_MEMBER = '[MEMBER APP] GET MEMBER';
export const GET_MEMBER_XPROFILE = '[MEMBER APP] GET MEMBER XPROFILE';
export const CLEAR_MEMBER = '[MEMBER APP] CLEAR MEMBER';
export const ERROR_MEMBER = '[MEMBER APP] ERROR MEMBER';
export const LOAD_MEMBER = '[MEMBER APP] LOAD MEMBER';

export function getMember({ id }) {

    return (dispatch) => {
        dispatch(errorMember(false));
        dispatch(isLoadMember(true));

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/members/${ id }`)
            .then((response) => {
                dispatch({
                    type: GET_MEMBER,
                    payload: response.data
                });

                dispatch(getMemberXprofile({ id }));

            })
            .catch(() => {
                dispatch(errorMember(true));
                dispatch(isLoadMember(false));
            })
    }
}

export function getMemberXprofile({ id }) {

    return (dispatch) => {
        dispatch(errorMember(false));

        axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/xprofile/fields?user_id=${ id }&fetch_field_data=true&contect=view`)
            .then((res) => {
                dispatch({
                    type: GET_MEMBER_XPROFILE,
                    payload: res.data
                });
                dispatch(isLoadMember(false));
            })
            .catch(() => {
                dispatch(errorMember(true));
                dispatch(isLoadMember(false));
            })
    }
}

export function clearMember() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_MEMBER
        });
    }
}

export function errorMember(isError) {

    return (dispatch) => {
        dispatch({
            type: ERROR_MEMBER,
            payload: isError
        });
    }
}

export function sendNewMessage({ message, subject }) {

    return (dispatch, getState) => {
        const { id, mention_name } = getState().memberApp.member.data;

        // console.log(getState().memberApp.member.data);

        return axios.post(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/messages`, {}, {
            params: {
                message,
                recipients: [id],
                subject: subject
            }
        })
    }
}

export function isLoadMember(isLoad) {

    return (dispatch) => {
        dispatch({
            type: LOAD_MEMBER,
            payload: isLoad
        });
    }
}
