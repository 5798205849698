import * as Actions from '../actions';

const initialState = {
    error: null,
    success: null
};

const changePasswordReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.CHANGE_PASSWORD_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                success: action.payload
            };
        }
        case Actions.CLEAR: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default changePasswordReducer;
