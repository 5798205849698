import axios from 'axios';
import * as CONSTANTS from "../../constants";

export const GET_SEARCH = '[SEARCH APP] GET SEARCH';
export const CLEAR_SEARCH = '[SEARCH APP] CLEAR';
export const SET_EMPTY_SEARCH = '[SEARCH APP] SET EMPTY SEARCH';
export const SET_FIELD_SEARCH = '[SEARCH APP] SET FIELD SEARCH';

export function getSearch({ per_page = 10, search, subtype = 'any' }) {

    return (dispatch) => {
        dispatch(setEmptySearch(false));

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/search`, {
            params: {
                per_page,
                search,
                subtype,
                context: 'view'
            }
        })
            .then((response) => {
                if (!response.data.length) {
                    dispatch(setEmptySearch(true));
                }
                dispatch({
                    type: GET_SEARCH,
                    payload: response.data
                })
            })
    }
}

export function clearSearch() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_SEARCH
        });
    }
}

export function searchField(data) {

    return (dispatch) => {
        dispatch({
            type: SET_FIELD_SEARCH,
            payload: data
        });
    }
}

export function setEmptySearch(isEmpty) {

    return (dispatch) => {
        dispatch({
            type: SET_EMPTY_SEARCH,
            payload: isEmpty
        });
    }
}