import React from "react";
import { Container, Row } from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import ProfileLessons from "./ProfileLessons";
import SideMenu from "./SideMenu";
import { Link } from "@reach/router";

const Lessons = (props) => {

    return (
        <>
                <Container fluid>
                    <Row>
                        <SideMenu/>
                        <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                            <Container className="py-2 my-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            Sessions
                                        </li>
                                    </ol>
                                </nav>
                            </Container>
                        </section>
                        <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                            <div className="academy-dash__content">
                                <div className="container">
                                    <Row className="no-gutters d-flex mb-3">
                                        <ProfileLessons/>
                                    </Row>
                                </div>
                            </div>
                        </main>
                    </Row>
                </Container>
        </>
    )
};

export default withReducer('profileApp', reducer)(Lessons);
