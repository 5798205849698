import * as Actions from '../actions';

const initialState = {
    data: [],
    empty: false,
    search: ''
};

const searchReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SEARCH: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.SET_EMPTY_SEARCH: {
            return {
                ...state,
                empty: action.payload
            };
        }
        case Actions.SET_FIELD_SEARCH: {
            return {
                ...state,
                search: action.payload
            };
        }
        case Actions.CLEAR_SEARCH: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default searchReducer;
