/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import formurlencoded from "form-urlencoded";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { Alert,  Button, Container, Row, Col, Form, Modal } from "react-bootstrap";
import SideMenu from "./SideMenu";
import * as CONSTANTS from "../../../../constants";

Date.prototype.getWeek = function() {
    var onejan = new Date(this.getFullYear(),0,1);
    var today = new Date(this.getFullYear(),this.getMonth(),this.getDate());
    var dayOfYear = ((today - onejan + 86400000)/86400000);
    return Math.ceil(dayOfYear/7)
};


const initForm = {
    author_name: '',
    author_url: '',
    author_email: '',
    content: '',
};

const Updates = (props) => {

    const [form, setForm] = useState(initForm);
    const user = useSelector(({ auth }) => auth.user);
    const [confirmationHTML, setConfirmationHTML] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {register, handleSubmit, errors} = useForm();

    const today = new Date();
    const currentWeekNumber = today.getWeek();

    const onSubmit = values => {

        let form_url = CONSTANTS.SITE_URL + `/wp-json/gf_entry/v2/`;
        const {userTarget, userLearned, userMorale, userGoals, userChallenge, userSold, userHomework} = values;

        // const entry = {
        //     email: user.email,
        //     name: user.name,
        //     userTarget: userTarget,
        //     userLearned: userLearned,
        //     userMorale: userMorale,
        //     userGoals: userGoals,
        //     userChallenge: userChallenge,
        //     userSold: userSold,
        //     userHomework: userHomework,
        //     userWeek: currentWeekNumber,
        //     username: user.username,
        //     user_id: user.id,
        //     form_id: 3,
        // };

        const formData = new URLSearchParams();
        formData.append('email', user.email);
        formData.append('name', user.name);
        formData.append('userTarget', userTarget);
        formData.append('userLearned', userLearned);
        formData.append('userMorale', userMorale);
        formData.append('userGoals', userGoals);
        formData.append('userChallenge', userChallenge);
        formData.append('userSold', userSold);
        formData.append('userHomework', userHomework);
        formData.append('userWeek', currentWeekNumber);
        formData.append('username', user.username);
        formData.append('user_id', user.id);
        formData.append('form_id', 3);

        const sendData = async () => {
            // setIsLoading(true);

            await axios({
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                method: 'post',
                url: form_url,
                responseType: 'json',
                data: formData,
            })
                .then(async function (response) {

                    try {
                        if ( response.data.data && response.data.data[0].code === 23 ) {
                            setErrorMessage(response.data.data[0].message)
                        }

                        let gravity_response = JSON.parse(response.data.body); // this is how you parse a string into JSON

                        if (gravity_response && gravity_response.is_valid) {
                            setConfirmationHTML(gravity_response.confirmation_message);
                        } else {

                            setErrorMessage('There was an error while processing your contact request.')
                        }

                    } catch (ex) {
                        // console.error(ex);
                    }
                });
        };

        sendData();
    };

    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const number_options = numbers.map((i) =>
        <option key={i} value={i}>{i}</option>
    );

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content px-0 px-md-4">
                            <div className="bg-primary px-md-4 py-md-3 p-1 rounded-top">
                                <div className="row d-flex align-items-center">
                                    <div className="col">
                                        <h4>
                                            <span className="text-white">My Weekly Update</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <Col xs={ 12 } md={ 12 } className="card border-top-0">
                                <div className="card-body p-1 p-md-4">
                                    {!confirmationHTML ? (
                                        <>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="form-row">
                                                    <div className="form-group col-12">
                                                        <label className="label" htmlFor="userTarget">
                                                            Potential Clients/Customers talked to in the last week
                                                        </label>
                                                        <select
                                                            name="userTarget"
                                                            ref={register({
                                                                required: "Please fill in a number"
                                                            })}
                                                            className="form-control"
                                                            placeholder=""
                                                            id="userTarget"
                                                        >
                                                            <option>--Choose an option--</option>
                                                            <option value="0">0</option>
                                                            {number_options}
                                                        </select>
                                                        {errors.userTarget && (
                                                            <p className="text-error">{errors.userTarget.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label" htmlFor="userLearned">
                                                            What have you learnt from them?
                                                        </label>
                                                        <input
                                                            name="userLearned"
                                                            ref={register({
                                                                required: "Please fill in an answer"
                                                            })}
                                                            className="form-control"
                                                            placeholder=""
                                                            id="userLearned"
                                                        />
                                                        {errors.userLearned && (
                                                            <p className="text-error">{errors.userLearned.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label"  htmlFor="userMorale">
                                                            On a scale of 1-10 what is your morale?
                                                        </label>
                                                        <select name="userMorale"
                                                                className="form-control"
                                                                placeholder="Please select a number."
                                                                ref={register({
                                                                    required: "Please fill in an answer"
                                                                })}
                                                        >
                                                            <option>--Choose an option--</option>
                                                            {number_options}
                                                        </select>
                                                        {errors.userMorale && (
                                                            <p className="text-error">{errors.userMorale.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label" htmlFor="userGoals">
                                                            What are your top 3 goals for this week?
                                                        </label>
                                                        <input
                                                            name="userGoals"
                                                            ref={register({
                                                                required: "Please fill in an answer"
                                                            })}
                                                            className="form-control"
                                                            placeholder=""
                                                            id="userGoals"
                                                        />
                                                        {errors.userGoals && (
                                                            <p className="text-error">{errors.userGoals.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label" htmlFor="userChallenge">
                                                            What is your biggest challenge?
                                                        </label>
                                                        <input
                                                            name="userChallenge"
                                                            ref={register({
                                                                required: "Please fill in an answer"
                                                            })}
                                                            className="form-control"
                                                            placeholder=""
                                                            id="userChallenge"
                                                        />
                                                        {errors.userChallenge && (
                                                            <p className="text-error">{errors.userChallenge.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label"  htmlFor="userSold">
                                                            Have you sold anything?
                                                        </label>
                                                        <select name="userSold"
                                                                className="form-control"
                                                                placeholder="Please select an answer."
                                                                ref={register({
                                                                    required: "Please fill in an answer"
                                                                })}
                                                        >
                                                            <option>--Choose an option--</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                        {errors.userSold && (
                                                            <p className="text-error">{errors.userSold.message}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label className="label" htmlFor="userHomework">
                                                            What did you learn from your homework this week?
                                                        </label>
                                                        <input
                                                            name="userHomework"
                                                            ref={register({
                                                                required: "Please fill in an answer"
                                                            })}
                                                            className="form-control"
                                                            placeholder=""
                                                            id="userHomework"
                                                        />
                                                        {errors.userHomework && (
                                                            <p className="text-error">{errors.userHomework.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <button className="btn btn-secondary request-button" type="submit">
                                                       Send
                                                    </button>
                                                    {errorMessage && <span className="ml-3 alert alert-warning">{errorMessage}</span>}
                                                </div>
                                            </form>
                                            </>
                                    ) : (
                                        <>
                                            <p className="form__comfirmation-text alert alert-primary" dangerouslySetInnerHTML={{__html: confirmationHTML}}/>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </div>
                    </main>
                </Row>
            </Container>
        </>

    );

}


export default Updates;
