import React from 'react';
import { Link } from "@reach/router";
import {IoIosLogIn} from 'react-icons/io';
import {IoIosLogOut} from 'react-icons/io';
import {FaRegAddressBook} from 'react-icons/fa';
import {FaRegRegistered} from 'react-icons/fa';
import {MdMessage} from 'react-icons/md';
import {ImLibrary} from 'react-icons/im';
import {FaUser} from 'react-icons/fa';
import {FaUsers} from 'react-icons/fa';
import {GoDashboard} from 'react-icons/go';
import {FaCalendarCheck} from 'react-icons/fa';
import {FaBuilding} from 'react-icons/fa';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "../store/actions";
import {MenuSwitch} from './MenuSwitch';


export const MobileTabs = () => {

  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

    return (
        <>
            <div className="mobile-area">
                <MenuSwitch className="mobile-area__switch"/>
                <nav className="mobile-tabs top">
                    { user ? (
                        <>
                            <Link className="mobile-tabs__link" to='/'>
                                <div className="mobile-tabs__item">
                                    <GoDashboard/>
                                    <span>Dashboard</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='/profile'>
                                <div className="mobile-tabs__item">
                                    <FaUser/>
                                    <span>Profile</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='members/browse'>
                                <div className="mobile-tabs__item">
                                    <FaUsers/>
                                    <span>Members</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" state={{group_id: 2}} to='/profile/company'>
                                    <div className="mobile-tabs__item">
                                        <FaBuilding/>
                                        <span>Company Profile</span>
                                    </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='/lessons'>
                                <div className="mobile-tabs__item">
                                    <FaRegAddressBook/>
                                    <span>Lessons</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='/previous-updates'>
                                <div className="mobile-tabs__item">
                                    <FaCalendarCheck/>
                                    <span>My Updates</span>
                                </div>
                            </Link>
                            <a
                                className="mobile-tabs__link"
                                href='https://drive.google.com/drive/folders/1AZKmDz---uIrRIujFtrTjpJm8IlzWfWI'>
                                <div className="mobile-tabs__item">
                                    <ImLibrary/>
                                    <span>Library of Good Things</span>
                                </div>
                            </a>
                            {/*<a className="mobile-tabs__link" href={`${site.siteMetadata.siteUrl}/dashboard/messages`}>*/}
                            {/*    <div className="mobile-tabs__item">*/}
                            {/*        <MdMessage/>*/}
                            {/*        <span>Messages</span>*/}
                            {/*    </div>*/}
                            {/*</a>*/}
                            <button
                                className="mobile-tabs__link"
                                onClick={ () => dispatch(Actions.logout()) }
                            >
                                <div className="mobile-tabs__item">
                                    <IoIosLogOut/>
                                    <span>Logout</span>
                                </div>
                            </button>
                        </>
                    ) : (
                        <>
                            <Link className="mobile-tabs__link" to='/'>
                                <div className="mobile-tabs__item">
                                    <GoDashboard/>
                                    <span>Dashboard</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='/register/'>
                                <div className="mobile-tabs__item">
                                    <FaRegRegistered/>
                                    <span>Sign up</span>
                                </div>
                            </Link>
                            <Link className="mobile-tabs__link" to='/login/'>
                                <div className="mobile-tabs__item">
                                    <IoIosLogIn/>
                                    <span>Login</span>
                                </div>
                            </Link>
                        </>
                    )}
                </nav>
            </div>
        </>
    );
};

