import React, {useEffect} from "react";
import {Col, Container, Row, Alert, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../Profile/store/actions';
import { navigate } from "@reach/router"
import SideMenu from "../Profile/components/SideMenu"
import ProfileInfo from "../../components/ProfileInfo";
import reducer from "../Profile/store/reducers";
import withReducer from "../../../store/withReducer";
import {useAttributeClass} from '../../../helpers/hooks/themeHooks';
import { SvgCheckTrue, SvgCheckFalse } from "../../../components/svgs"
import { Link } from "@reach/router";
import {Video} from "../../../components/Video";
import fileWebm from "../../../videos/introduction.webm";
import fileMp4 from "../../../videos/introduction.mp4"

const Dashboard = ({ location, data }) => {
    const dispatch = useDispatch();
    const xprofile = useSelector(({ profileApp }) => profileApp.profile.xprofile);
    const main_data = useSelector(({ profileApp }) => profileApp.profile.main_data);
    const avatar = useSelector(({ profileApp }) => profileApp.profile.avatar);
    const upload_error = useSelector(({ profileApp }) => profileApp.profile.upload_error);
    const upload_success = useSelector(({ profileApp }) => profileApp.profile.upload_success);
    const weekData = useSelector(({ weekReducer }) => weekReducer.data);
    const isWeekInApi = useSelector(({ weekReducer }) => weekReducer.isWeekInApi);
    const cohort = useSelector(({profileApp}) => profileApp.profile.cohort);
    const weeks = new Array(52).fill();
    const user = useSelector(({ auth }) => auth.user);

    const { state = {} } = location;
    const { group_id } = state ? state : 1;

    useEffect(() => {
        dispatch(Actions.getUserXprofileData({
            group_id: group_id,
            load: true,
        }));
        dispatch(Actions.getCohort());
        dispatch(Actions.getUserMainData());
        dispatch(Actions.getUserMe());
        dispatch(Actions.getWeeks());


        return () => {
            dispatch(Actions.clearDashboard())
        }

    }, [dispatch, group_id, user]);

    return (
        <>
            <div className="bg-lightgreen academy-dash__welcome mb-3 py-2">
                <Container>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xs={12} md={9}
                             className="d-flex align-items-center justify-content-center offset-md-3">
                            {(main_data && xprofile) && (
                                <>
                                    <ProfileInfo
                                        avatar={avatar}
                                        upload_error={upload_error}
                                        upload_success={upload_success}
                                        // name={ main_data.name }
                                    />
                                </>
                            )}
                            <div className="ml-3">
                                <h1 className="academy-dash__welcome--title mb-0">Welcome back, <b>{user.name}</b>
                                </h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content">
                            <Row className="no-gutters d-flex mb-8">
                                <Col xs={12} className="card">
                                    <div className="card-header bg-transparent px-md-4 py-md-3 p-2">
                                        <div className="row d-flex align-items-center">
                                            <div className="col">
                                                <h4 className="m-0 d-flex align-items-center">
                                                    <span>Welcome to Startup School</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    {typeof cohort !== 'undefined' && cohort.length &&
                                    Object.keys(cohort).map((i) => {
                                        const cohort_ids = user.acf.cohort_id;
                                        if (cohort_ids && cohort_ids.includes(cohort[i].id)) {
                                            return (
                                                <div className="card-body p-2 p-md-4" key={i}
                                                     dangerouslySetInnerHTML={{__html: cohort[i].description}}/>
                                            )
                                        }
                                    })
                                    }
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xs={12} md={6}>
                                    <div xs={12} md={6} className="card card__checkIn mb-3">
                                        <div className="card-header bg-transparent px-md-4 py-md-3 p-2">
                                            <div className="row d-flex align-items-center">
                                                <div className="col">
                                                    <h4 className="m-0 d-flex align-items-center">
                                                        <span>My Updates</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-2 p-md-4 d-flex flex-wrap">
                                            <div className="d-block w-100 mb-3">
                                                {isWeekInApi ?
                                                    <>
                                                        <div className="alert alert-primary clearfix" role="alert">
                                                            You have submitted this weeks update. Good job!
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div className="alert alert-warning d-flex align-items-center"
                                                             role="alert">
                                                            <p className="m-0 mr-3">You havent submitted this weeks
                                                                update yet.</p>
                                                            <Link
                                                                className="ml-auto btn btn-primary"
                                                                to={'/updates'}>
                                                                Send in your update
                                                            </Link>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="d-block w-100">
                                                {weeks && weeks.map((_, index) => {
                                                    if (weekData[index + 1]) {
                                                        return (
                                                          <SvgCheckTrue key={index}/>
                                                        )
                                                    }
                                                    return (
                                                      <SvgCheckFalse key={index}/>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="card card__video">
                                        <div className="card-header bg-transparent px-md-4 py-md-3 p-2">
                                            <div className="row d-flex align-items-center">
                                                <div className="col">
                                                    <h4 className="m-0 d-flex align-items-center">
                                                        <span>Getting Started</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-2 p-md-4 d-flex flex-wrap">
                                            <Video fileWebm={fileWebm} fileMp4={fileMp4} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </main>
                </Row>
            </Container>
        </>
    )
};


export default withReducer('profileApp', reducer)(Dashboard);
// export default Dashboard;
