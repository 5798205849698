import { combineReducers } from 'redux';
import messages from './messages.reducer';
import users from './users.reducer';
import userDialog from './userDialog.reducer';

const reducer = combineReducers({
    messages,
    users,
    userDialog
});

export default reducer;
