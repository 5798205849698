import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaCheck, FaDiaspora, FaDownload, FaMapPin } from "react-icons/fa";
import { getAvatar } from "../../constants";
import { useDispatch } from "react-redux";
import * as Actions from '../pages/Profile/store/actions';

const ProfileInfo = ({ avatar, upload_success, upload_error, name = false, view }) => {

    const dispatch = useDispatch();

    const [validate_wh_image, setValidate_wh_image] = useState(false);

    const handleUploadChange = (e) => {
        setValidate_wh_image(false);

        const file = e.target.files[0];

        if (!file) {
            return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            const img = new window.Image();

            img.src = e.target.result;

            img.onload = function () {
                if (this.width > 150 && this.height > 150) {
                    const data = new window.FormData();
                    data.append('file', file, file.name || file.type.replace('/', '.'));

                    dispatch(Actions.uploadAvatar(data));
                } else {
                    setValidate_wh_image(true);
                }
            };
        };
    };

    return (
            <div className="d-flex justify-content-center flex-column">
            {
                view
                    ? <Image className="mx-auto" src={ getAvatar(avatar) } width={ 100 } height={ 100 } roundedCircle/>
                    : (
                        <label
                            htmlFor="button-file"
                            className="label-file"
                        >
                            <div className="downIconOver">
                                <FaDownload className="downIcon"/>
                            </div>
                            <Image src={ getAvatar(avatar) } width={ 100 } height={ 100 } roundedCircle/>
                            <input
                                accept="image/*"
                                className="hidden"
                                id="button-file"
                                type="file"
                                onChange={ handleUploadChange }
                            />
                        </label>
                    )
            }
            {
                name && (
                    <h4 className="text-center mt-3 font-weight-bold">{name}</h4>
                )
            }
            {
                upload_success && (
                    <div className="d-flex justify-content-center align-items-center">
                        <FaCheck fill="green"/>
                        <span className="ml-2">Success</span>
                    </div>
                )
            }
            {
                upload_error && (
                    <div className="d-flex justify-content-center align-items-center">
                        <FaDiaspora fill="red"/>
                        <span className="ml-2">Error</span>
                    </div>
                )
            }
            {
                validate_wh_image && (
                    <div className="d-flex justify-content-center align-items-center">
                        <FaDiaspora fill="red"/>
                        <span className="ml-2">
                            Pixel sizes must be 150x150
                        </span>
                    </div>
                )
            }
            </div>
    );
};

export default ProfileInfo;
