import React, { useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import withReducer from "../../../store/withReducer";
import reducer from "./store/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import MembersList from "./components/MembersList";
import get from 'lodash/get';

import './style/members.css';
import SideMenu from "../Profile/components/SideMenu";
import ProfileInfo from "../../components/ProfileInfo";
import { Link } from "@reach/router";

const Members = (props) => {
    const dispatch = useDispatch();

    const data = useSelector(({ membersApp }) => membersApp.members.data);
    const error = useSelector(({ membersApp }) => membersApp.members.error);
    const members_max = useSelector(({ membersApp }) => membersApp.members.members_max);
    const per_page = useSelector(({ membersApp }) => membersApp.members.per_page);
    const search = useSelector(({ membersApp }) => membersApp.members.search);

    useEffect(() => {
        if (!get(props, 'location.state.noRender') || !data.length) {
            dispatch(Actions.getMembers());
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(Actions.getMembers())
            .then(() => dispatch(Actions.perpageMembers(12)));
    };

    const handlerReset = (e) => {
        e.preventDefault();

        dispatch(Actions.searchMembers(''));

        dispatch(Actions.perpageMembers(12));

        dispatch(Actions.getMembers());
    };

    const loadMore = () => {
        const new_per_page = per_page + 12;

        dispatch(Actions.perpageMembers(new_per_page));

        dispatch(Actions.getMembers());
    };

    const validate = () => (
        search.length
    );

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                        <Container fluid className="py-2 my-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Members
                                    </li>
                                </ol>
                            </nav>
                        </Container>
                    </section>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content">
                            <Row className="no-gutters d-flex">
                                <Col xs={ 12 } className="px-3">
                                <p className="mb-3">Browse members by name, location, job title, interests…</p>
                                    <Form
                                        className="search"
                                        inline
                                        onSubmit={ handlerSubmit }
                                    >
                                        <Form.Group controlId="formBasicSearch">
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    className="search__input"
                                                    name="search"
                                                    placeholder="Search"
                                                    value={ search }
                                                    onChange={ ({ target }) => dispatch(Actions.searchMembers(target.value)) }
                                                />
                                                <InputGroup.Append>
                                                    <button
                                                        disabled={ !validate() }
                                                        className="search__button"
                                                    >
                                                        <svg width="29" height="29" viewBox="0 0 29 29">
                                                            <g>
                                                                <g>
                                                                    <path
                                                                        fill="#145c4b"
                                                                        d="M20.19 18.167l8.106 7.916-2.423 2.361-8.121-7.902v-1.254l-.449-.434a10.678 10.678 0 0 1-6.866 2.48c-5.833 0-10.562-4.608-10.562-10.292S4.604.75 10.438.75C16.27.75 21 5.357 21 11.042c0 2.557-.964 4.89-2.548 6.69l.448.435zm-9.753 0c4.039 0 7.313-3.19 7.313-7.125s-3.274-7.125-7.313-7.125c-4.04 0-7.312 3.19-7.312 7.125 0 3.936 3.273 7.125 7.313 7.125z"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                        {
                                            Boolean(validate()) && (
                                                <Button
                                                    variant="secondary"
                                                    type="button"
                                                    className="ml-3"
                                                    onClick={ handlerReset }
                                                >
                                                    Reset
                                                </Button>
                                            )
                                        }
                                    </Form>
                                </Col>
                                {
                                    error && (
                                        <Col xs={ 12 } className="mt-4 px-3">
                                            <Alert variant="danger">
                                                No members found.
                                            </Alert>
                                        </Col>
                                    )
                                }
                                <Col xs={ 12 } className="mt-4">
                                    <MembersList data={ data }/>
                                </Col>
                                {
                                    data.length < members_max && (
                                        <Col xs={ 12 } className="mt-2 px-3">
                                            <Button
                                                onClick={ loadMore }
                                            >
                                                Load more
                                            </Button>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    </main>
                </Row>
            </Container>
        </>
    )
};

export default withReducer('membersApp', reducer)(Members);