import { combineReducers } from 'redux';
import auth from './auth.reducer';
import search from './search.reducer';
import comment from './comment.reducer';
import comments from './comments.reducer';
import weekReducer from '../../profile/pages/Profile/store/reducers/weekReducer.reducer';
import updateReducer from '../../profile/pages/Profile/store/reducers/updateReducer';

const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        search,
        comments,
        comment,
        weekReducer,
        updateReducer,
        ...asyncReducers
    });

export default createReducer;
