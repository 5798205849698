import React from "react";
import ProfileEditField from "./ProfileEditField";
import find from 'lodash/find';
import get from 'lodash/get';
import {Alert, Col, Row} from "react-bootstrap";
import {Location} from "@reach/router";
import { Link } from "@reach/router";

function strip(html){
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const ProfileGroups = ({ fields, view, group_id }) => (
    <>
        {
            fields.map(({ name, data, type, id }, i) => {

                return (
                        <Location>
                        {({ location }) => (
                            <>
                            { location.pathname.includes("profile") ? (
                                <React.Fragment key={ i }>
                                    <dd className="col-12 col-lg-6 font-weight-bold mb-2 mb-lg-4">{ name }</dd>
                                    <ProfileEditField
                                        valueRaw={ type === 'checkbox' ? data.value.raw : strip(data.value.rendered) }
                                        type={ type }
                                        field_id={ id }
                                        group_id={ group_id }
                                        view={ view }
                                    />
                                </React.Fragment>
                            ) : (
                                <>
                                    {
                                        data.value.raw && (
                                            <React.Fragment key={ i }>
                                                <dd className="col-12 col-lg-6 font-weight-bold">{ name }</dd>
                                                <ProfileEditField
                                                    valueRaw={ type === 'checkbox' ? data.value.raw : strip(data.value.rendered) }
                                                    type={ type }
                                                    field_id={ id }
                                                    group_id={ group_id }
                                                    view={ view }
                                                />
                                                <hr/>
                                            </React.Fragment>
                                        )
                                    }
                                </>
                            )}
                            </>
                        )}
                    </Location>
                )
            })
        }
    </>
);

export default ProfileGroups;