import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_VIDEO = '[VIDEO APP] GET VIDEO';
export const SET_VIDEO_ERROR = '[VIDEO APP] GET VIDEO ERROR';
export const CLEAR_VIDEO = '[VIDEO APP] CLEAR';

export function getVideo({ id }) {

    return (dispatch) => {
        dispatch({
            type: SET_VIDEO_ERROR,
            payload: false
        });

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/video/${ id }?_embed`)
            .then((res) => {
                dispatch({
                    type: GET_VIDEO,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: SET_VIDEO_ERROR,
                    payload: true
                });
            });

    }
}

export function clearVideo() {

    return (dispatch) => dispatch({ type: CLEAR_VIDEO });
}