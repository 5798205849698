/* jshint ignore:start */
import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import formurlencoded from "form-urlencoded";
import * as CONSTANTS from '../../../constants'

const Form = (props) => {
    const [confirmationHTML, setConfirmationHTML] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {register, handleSubmit, errors} = useForm();

    const onSubmit = values => {
        let form_url = CONSTANTS.SITE_URL + `/wp-json/gf_entry/v2/`;
        const { email } = values;

        const entry = {
            email: email,
            form_id: 2
        };

        const sendData = async () => {
            // setIsLoading(true);

            await axios({
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                method: 'post',
                url: form_url,
                responseType: 'json',
                data: formurlencoded(entry),
            })
                .then(async function (response) {
                    console.log(response)
                    try {
                        let gravity_response = JSON.parse(response.data.body); // this is how you parse a string into JSON

                        if (gravity_response.is_valid) {
                            setConfirmationHTML(gravity_response.confirmation_message);
                        } else {
                            setErrorMessage('There was an error while processing your contact request.')
                        }

                    } catch (ex) {
                        // console.error(ex);
                    }
                });
        };

        sendData();
    };

    return (
        <>
            {!confirmationHTML ? (
                <form className="newsletter__form" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" id="form_id" name="form_id" value="2"/>
                    <div className="input-group mb-2 align-items-center input-group--newsletter">
                        <input
                            style={{height:"3em"}}
                            name="email"
                            ref={register({
                                required: "Please fill in your email"
                            })}
                            placeholder="Enter your email address"
                            aria-label="Enter your email address"
                            aria-describedby="button-contactEmail"
                            className="form-control"
                            id="contactEmail"
                        />
                        {errors.contactEmail && (
                            <p className="text-error">{errors.contactEmail.message}</p>
                        )}
                        <div className="position-absolute mx-2 send-button">
                            <button className="btn btn-primary" type="submit" id="button-contactEmail">
                                Send
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <p className="form__comfirmation-text alert alert-primary" dangerouslySetInnerHTML={{__html: confirmationHTML}}/>
                </>
            )}
        </>
    );
}

export default Form;