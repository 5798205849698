/* eslint-disable */
import React from 'react';

const SvgCheckTrue = () => (
  <svg id="Layer_1" enableBackground="new 0 0 511.978 511.978" height="24" width="24" viewBox="0 0 511.978 511.978" width="30" fill='grey' xmlns="http://www.w3.org/2000/svg"><g><path d="m436.947 74.979c-48.335-48.351-112.601-74.979-180.958-74.979s-132.623 26.628-180.958 74.979c-48.334 48.35-74.953 112.634-74.953 181.01s26.619 132.66 74.953 181.01c48.335 48.351 112.601 74.979 180.958 74.979s132.623-26.628 180.958-74.979c48.334-48.35 74.953-112.633 74.953-181.01 0-68.376-26.619-132.66-74.953-181.01zm-21.217 340.81c-42.668 42.683-99.399 66.189-159.742 66.189s-117.073-23.506-159.742-66.189c-42.67-42.684-66.169-99.435-66.169-159.8s23.5-117.116 66.169-159.8c42.67-42.683 99.401-66.189 159.743-66.189s117.073 23.506 159.742 66.189c42.67 42.684 66.17 99.435 66.17 159.8s-23.5 117.116-66.171 159.8z"/><path d="m366.382 135.436c-5.857-5.858-15.355-5.858-21.213 0l-121.138 121.137-56.713-56.713c-5.742-5.743-15.012-5.873-20.912-.293l-44.841 42.396c-2.944 2.783-4.637 6.639-4.693 10.689s1.527 7.952 4.392 10.816l113.051 113.052c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394l175.363-175.363c5.858-5.858 5.858-15.355 0-21.213zm-141.46 209.264-91.536-91.536 23.033-21.777 57.006 57.006c5.857 5.858 15.355 5.858 21.213 0l121.138-121.137 23.295 23.295z"/></g></svg>
);

export default SvgCheckTrue;




