import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../store/actions';
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import { Link } from "@reach/router";

const initForm = {
    author_name: '',
    author_url: '',
    author_email: '',
    content: '',
};

const CommentForm = (props) => {

    const dispatch = useDispatch();

    const load = useSelector(({ comment }) => comment.load);
    const success = useSelector(({ comment }) => comment.success);
    const user = useSelector(({ auth }) => auth.user);
    const error = useSelector(({ comment }) => comment.error);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [form, setForm] = useState(initForm);

    useEffect(() => {
        if ( user ) {
            setForm((form) => ({
                ...form,
                author_email: user.email || '',
                author_name: user.name || '',
            }));

            return () => {
                dispatch(Actions.clearComment())
            }
        }
        // eslint-disable-next-line
    }, [dispatch]);

    const handlerForm = ({ target }) => {
        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        handleShow();
    };

    const handlerConfirm = () => {
        dispatch(Actions.sendComment({
            author_name: form.author_name,
            author_url: form.author_url,
            author_email: form.author_email,
            content: form.content,
            post: props.id
        }))
            .then(() => {
                setForm(initForm)
            });

        handleClose();
    };

    const validate = () => (
        form.author_name.length
        // && form.author_url.length
        && form.author_email.length
        && form.content.length
        && !load
    );

    if ( user ) {
        return (
            <Form onSubmit={handlerSubmit}>
                {
                    error && (
                        <Alert variant="danger">
                            <div dangerouslySetInnerHTML={{__html: error}}/>
                        </Alert>
                    )
                }
                {
                    success && (
                        <Alert variant="success">
                            Comment sent
                        </Alert>
                    )
                }
                <Form.Group controlId="formBasicAuthor" className="d-none">
                    <Form.Label>Author</Form.Label>
                    <Form.Control
                        type="hidden"
                        name="author_name"
                        value={form.author_name}
                        onChange={handlerForm}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="d-none">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="hidden"
                        name="author_email"
                        value={form.author_email}
                        onChange={handlerForm}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicWebsite" className="d-none">
                    <Form.Label>Website</Form.Label>
                    <Form.Control
                        type="hidden"
                        name="author_url"
                        value={form.author_url}
                        onChange={handlerForm}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicComment">
                    {/*<Form.Label>Comment</Form.Label>*/}
                    <Form.Control
                        as="textarea"
                        placeholder="Add a public comment"
                        rows="4"
                        name="content"
                        value={form.content}
                        onChange={handlerForm}
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={!validate()}
                >
                    Submit
                </Button>

                {/* Modal */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Post a comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to post a comment?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handlerConfirm}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        )
    } else {

        return (

            <Link to="/login/">
                Login to leave a comment
            </Link>
        )
    }
};

export default CommentForm;