import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_DEAL = '[DEAL APP] GET DEAL';
export const GET_DEAL_ERROR = '[DEAL APP] GET DEAL ERROR';
export const CLEAR_DEAL = '[DEAL APP] CLEAR';

export function getDeal(id) {

    return (dispatch) => {
        dispatch({
            type: GET_DEAL_ERROR,
            payload: false
        });

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/deal/${id}?_embed`)
            .then((res) => {
                dispatch({
                    type: GET_DEAL,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: GET_DEAL_ERROR,
                    payload: true
                });
            })
    }
}

export function clearDeal() {

    return (dispatch) => dispatch({ type: CLEAR_DEAL });
}
