import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_UPDATE_DATA = '[UPDATE] GET UPDATE DATA';

export const getUpdateAction = () => async (dispatch) => {
    try {
        const res = await axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/updates/`);
        console.log(JSON.parse(res.data));
        return dispatch({
            type: GET_UPDATE_DATA,
            payload: JSON.parse(res.data),
        })
    } catch (error) {
        console.log(error, 'update error');
    }
}