import get from "lodash/get";
import moment from "moment";

export const SITE_URL = 'https://startupschoolforseniors.com';

export const transformDate = (date) => {
    if (!date) return 'not specified';

    const dateFormat = new Date(date);

    const visualNumDate = (item) => item < 10 ? '0' + item : item;

    const curr_day = visualNumDate(dateFormat.getDate());
    const curr_month = visualNumDate(dateFormat.getMonth() + 1);
    const curr_year = visualNumDate(dateFormat.getFullYear());

    return `${ curr_day }/${ curr_month }/${ curr_year }`;
};

export const transformDateForAPI = (date) => {
    const dateFormat = new Date(date);

    const visualNumDate = (item) => item < 10 ? '0' + item : item;

    const curr_day = visualNumDate(dateFormat.getDate());
    const curr_month = visualNumDate(dateFormat.getMonth() + 1);
    const curr_year = visualNumDate(dateFormat.getFullYear());

    return `${ curr_year }-${ curr_month }-${ curr_day } 00:00:00`;
};

export const getAvatar = (avatar) => {
    if (!avatar) return '';

    const { avatar_urls, meta } = avatar;

    let avatar_url = '';

    if (get(meta, 'avatar[0]')) {
        return get(meta, 'avatar[0]');
    }

    const avatar_urls_keys = Object.keys(avatar_urls);
    const avatar_urls_values = Object.values(avatar_urls);

    if (avatar_urls_keys.length) {
        return avatar_urls_values[avatar_urls_keys.length - 1]
    }

    return avatar_url;
};

export const isRelease = (release_date) => moment(release_date, 'YYYYMMDD').isBefore(moment());

export const is_state = (id, user_acf, state_name) => {
    if (get(user_acf, state_name)) {

        if (get(user_acf, state_name).some((item_id) => id === item_id)) {
            return true;
        }
    }

    return false;
};
