import * as Actions from '../actions';

const initialState = {
    data: null,
    error: false
};

const videoReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_VIDEO: {
            return {
                ...state,
                data: action.payload
            }
        }
        case Actions.SET_VIDEO_ERROR: {
            return {
                ...state,
                error: action.payload
            }
        }
        case Actions.CLEAR_VIDEO: {
            return initialState
        }
        default: {
            return state;
        }
    }
};

export default videoReducer;
