import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { transformDate, transformDateForAPI } from "../../constants";
import * as Actions from '../pages/Profile/store/actions';
import { useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

const ProfileEditField = ({ group_id, valueRaw, type, field_id, view }) => {

    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [load, setLoad] = useState(true);
    const [form, setForm] = useState(null);
    const [oldForm, setOldForm] = useState(null);

    useEffect(() => {
        switch (type) {
            case 'datebox':
                setForm(valueRaw ? new Date(valueRaw) : valueRaw);
                break;
            case 'checkbox':
                setForm(valueRaw === 'a:1:{i:0;s:3:"Yes";}' ? 'Yes' : '');
                break;
            default:
                setForm(valueRaw || '');
        }

        setLoad(false);

    }, [valueRaw, type]);

    const handleSubmitForm = () => {
        setEdit(false);

        const valueField = () => {
            switch (type) {
                case 'datebox':
                    return transformDateForAPI(form);
                case 'checkbox':
                    return form ? 'Yes' : '';
                default:
                    return form;
            }
        };

        dispatch(Actions.setProfileField({ field_id, value: valueField() }))
            .then(() => {
                dispatch(Actions.getUserXprofileData({
                    group_id: group_id,
                    load: false,
                }));
            })
            .catch(() => {
                setForm(oldForm);
                alert('Error');
            })
    };

    const renderView = () => {

        switch (type) {
            case 'datebox':
                return transformDate(form);
            case 'checkbox':
                return form ? 'Yes' : 'No';
            default:
                return form || '-';
        }
    };

    const handlerEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmitForm();
        }
    };

    const renderFormControl = () => {

        switch (type) {
            case 'datebox':
                return (
                    <div>
                        <DatePicker
                            selected={ form }
                            onChange={ date => setForm(date) }
                            dateFormat="dd/MM/yyyy"
                            className="custom-input"
                        />
                    </div>
                );
            case 'checkbox':
                return (
                    <Form.Check
                        type="checkbox"
                        checked={ Boolean(form) }
                        onChange={ ({ target }) => setForm(target.checked) }
                        label="Yes"
                    />
                );
            case 'textarea':
                return (
                    <Form.Control
                        as="textarea"
                        rows="3"
                        value={ form }
                        onChange={ ({ target }) => setForm(target.value) }
                        className="custom-input"
                        onKeyPress={ handlerEnter }
                    />
                );
            default:
                return (
                    <Form.Control
                        type="text"
                        value={ form }
                        onChange={ ({ target }) => setForm(target.value) }
                        className="custom-input"
                        onKeyPress={ handlerEnter }
                    />
                );
        }
    };

    return (
        <>
            {
                !load && (
                    <>
                        {
                            edit
                                ? (
                                    <>
                                        <dd className={ `${ view ? 'col-lg-6 mb-3' : 'mb-3 col-lg-6' }` }>
                                            { renderFormControl() }
                                            <Button
                                                variant="info"
                                                className="mt-2"
                                                onClick={ handleSubmitForm }
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="light"
                                                className="ml-2 mt-2"
                                                onClick={ () => {
                                                    setEdit(false);
                                                    setForm(oldForm);
                                                } }
                                            >
                                                Cancel
                                            </Button>
                                        </dd>
                                    </>
                                )
                                : (
                                    <>
                                        <dd className={ `${ view ? 'col-lg-6' : 'col-lg-4' }` }>
                                            { renderView() }
                                        </dd>
                                        {
                                            !view && (
                                                <dd className="col-12 col-lg-2 mb-3 mb-lg-1">
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={ () => {
                                                            setEdit(true);
                                                            setOldForm(form);
                                                        } }
                                                    >
                                                        Edit
                                                    </Button>
                                                </dd>
                                            )
                                        }
                                    </>
                                )
                        }
                    </>
                )
            }
        </>
    )
};

export default ProfileEditField;