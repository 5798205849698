import React from "react";
import { Container, Row } from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import ProfileVideos from "./ProfileVideos";
import SideMenu from "./SideMenu";

const Videos = (props) => {

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 py-6">
                        <ProfileVideos/>
                    </main>
                </Row>
            </Container>
        </>
    )
};

export default withReducer('profileApp', reducer)(Videos);
