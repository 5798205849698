import { applyPolyfills, defineCustomElements } from '@deckdeckgo/youtube/dist/loader';
import get from "lodash/get";
import has from "lodash/has";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { isRelease, is_state } from "../../../constants";
import SideMenu from "../Profile/components/SideMenu";
import CommentList from "../../../components/Comment/components/CommentList";
import CommentForm from "../../../components/Comment/components/CommentForm";
import { Link } from "@reach/router";
import * as GeneralActions from "../../../store/actions";
import PostStatus from "../../../components/PostStatus";
import withReducer from "../../../store/withReducer";
import * as Actions from './store/actions';
import reducer from "./store/reducers";
import "./style/video.css";

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const Video = (props) => {

    const container = createRef();
    const cover = createRef();
    const dispatch = useDispatch();
    const shadow = '';

    const data = useSelector(({ videoApp }) => videoApp.video.data);
    const error = useSelector(({ videoApp }) => videoApp.video.error);
    const user_acf = useSelector(({ auth }) => auth.user.acf);
    const [played, setPlayed] = useState(false);
    const isPlayed = get(data, "acf.play_video_complete");

    const videoObserver = new
    IntersectionObserver(onVideoIntersection, {
        rootMargin: '100px 0px',
        threshold: 0.25
    });

    // Create some CSS to apply to the shadow dom
    let style = document.createElement('style');

    style.textContent = `
.youtube-container { position:relative;padding-bottom:56.25%;padding-top:30px;height:0;overflow:hidden; }
.youtube-container iframe { position:absolute;top:0;left:0;width:100%;height:100%; }
`;

    useEffect(() => {
        dispatch(Actions.getVideo({
            id: props.ID
        }));


// Select the web component, then the shadowRoot.
        const shadow = document.querySelector('deckgo-youtube').shadowRoot;

        shadow.appendChild(style);

        return () => {
            dispatch(Actions.clearVideo());
        }
    }, []);

    useEffect(() => {
        if (window && 'IntersectionObserver' in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else {
            loadVideo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ container ]);

    function onVideoIntersection(entries) {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            loadVideo();
            videoObserver.disconnect();
        }
    }

    async function loadVideo() {
        if (container && container.current) {
            container.current.lazyLoadContent();
        }
    }

    const handlePlay = async (local = false) => {
        console.log("we are playing the video")
        if (played) return;
        try {
            if (!local) {
                container.current.play();
                cover.current.classList.remove("cover-video");
            }
            setPlayed(true);
            if (!isPlayed) {
                await dispatch(
                GeneralActions.setStatusVideo({ post_id: String(data?.id), action: "add", field_name: "play_video_complete", post_type: "video" })
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const renderTitle = () => (
      <h1 className="text-left lead" dangerouslySetInnerHTML={{ __html: get(data, 'title.rendered') }} />
    );

    const renderContent = () => (
      <div dangerouslySetInnerHTML={{ __html: get(data, 'content.rendered') }} />
    );

    const renderTranscriptButton = () => {
        if (has(data, 'acf.video_transcript')) {

            let transcript = get(data, 'acf.video_transcript');

            return (
                <>
                    {transcript.link && (
                        <a className="text-link" target="_blank" rel="noreferrer"  href={transcript.url}>Download Transcript</a>
                    )}
                </>
            )
        }
    };

    const renderHomeWorkButton = () => {
        if (has(data, 'acf.video_homework')) {

            let video_homework = get(data, 'acf.video_homework');
            let video_homework_doc = get(data, 'acf.video_homework_doc');
            let video_worksheet = get(data, 'acf.video_worksheet');

            return (
                <>
                    {video_homework && video_homework.link && (
                        <>
                            <span className="mr-2">Download Homework: </span>
                            <a className="px-2 border mr-2" target="_blank" rel="noreferrer" href={video_homework.url}>PDF</a>
                        </>
                    )}
                    {video_homework_doc && video_homework_doc.link && (
                        <a className="px-2 border mr-2" target="_blank" rel="noreferrer" href={video_homework_doc.url}>DOC</a>
                    )}
                    {video_worksheet && video_worksheet.link && (
                        <a className="px-2 border" target="_blank" rel="noreferrer" href={video_worksheet.url}>Worksheet</a>
                    )}
                </>
            )
        }
    };

    const renderVideoItem = useCallback(() => {

        if (get(data, 'acf.type') === 'local') {
            return (
                <>
                    <div className="d-flex justify-content-center mt-2 mt-md-4 mb-3 mb-md-0 teretet">
                        <video
                            src={get(data, 'acf.video_link.url')}
                            width="700"
                            height="400"
                            controls
                            onPlay={() => handlePlay(true)}
                            style={{
                                "width": "100%",
                                "height": "100%",
                                "position": "relative",
                                "display": "block"
                            }}
                        />
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="d-flex justify-content-center d-block w-100 mt-2 mt-md-4 mb-3 mb-md-0" key={get(data, 'ID')}>
                    {isPlayed ? null : <div ref={cover} className="cover-video" onClick={()=>handlePlay(false)} />}
                    <deckgo-youtube
                        // width="500"
                        // height="auto"
                        ref={ container }
                        src={ get(data, 'acf.video_link.url') }
                        style={{
                            "width": "100%",
                            "height": "100%",
                            "position": "relative",
                            "display": "block"
                        }}
                        frameBorder="0"
                        allow="accelerometer;
                           autoplay;
                           encrypted-media;
                           gyroscope;
                           picture-in-picture"
                        allowFullScreen={true}
                    >
                    </deckgo-youtube>
                </div>
            </>
        )
    }, [data, container]);

    const renderVideo = () => {

        if (typeof window !== 'undefined') {
        // if (has(data, 'acf.release_date')) {
        //
        //     if (isRelease(get(data, 'acf.release_date'))) {
        //         return renderVideoItem();
        //     }
        //
        //     return (
        //         <Alert variant="danger" className="mt-4">
        //             Error video
        //         </Alert>
        //     );
        // }

            return renderVideoItem();

        }
    };


    function videoTitle(item) {
        return {__html: get(data, 'title.rendered')};
    }

    const renderVideoTitle = (item) => (
        <span dangerouslySetInnerHTML={videoTitle(item)}/>
    );

    return (
        <>
                <Container fluid>
                    <Row>
                        <SideMenu/>
                        <section className="col-12 col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                            <Container className="py-2 my-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/lessons">Sessions</Link>
                                        </li>
                                        {data ? (
                                                <>
                                                    <li className="breadcrumb-item">
                                                        <Link to={`/lesson/${get(data, 'acf.lesson.ID')}`}>{get(data, 'acf.lesson.post_title')}</Link>
                                                    </li>
                                                    <li className="breadcrumb-item active" aria-current="page">
                                                        {renderVideoTitle()}
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li className="breadcrumb-item active" aria-current="page">
                                                       Video not found
                                                    </li>
                                                </>
                                        )}
                                    </ol>
                                </nav>
                            </Container>
                        </section>
                        <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                            <div className="academy-dash__content">
                                <div className="container">
                                    {error ? (
                                            <Alert variant="danger" className="mt-4">
                                                Video not found, please go back to the lesson and select another video.
                                            </Alert>
                                    ) : (
                                        <>
                                        <Row>
                                            <Col xs={12} md={10} lg={10}>
                                                {/*<div className="d-flex mb-3 justify-content-between w-100">*/}
                                                {/*    {data && data.previous && (*/}
                                                {/*        <a className="font-weight-bold" href={ `/video/${ data.previous }` }>*/}
                                                {/*            Previous Video*/}
                                                {/*        </a>*/}
                                                {/*    )}*/}
                                                {/*    {data && data.next && (*/}
                                                {/*        <a className="font-weight-bold" href={ `/video/${ data.next }` }>*/}
                                                {/*            Next Video*/}
                                                {/*        </a>*/}
                                                {/*    )}*/}
                                                {/*</div>*/}
                                                <Row>
                                                    <Col xs={12} md={8} className="d-flex align-items-center">
                                                        { renderTitle() }
                                                    </Col>
                                                    <Col xs={12} md={4} className="d-flex flex-row justify-content-start justify-content-md-end align-items-center">
                                                        <PostStatus
                                                            item_id={ data?.id }
                                                            is_complete_state={ (param) => is_state(param, user_acf, 'video_complete') }
                                                            is_skipped_state={ (param) => is_state(param, user_acf, 'video_skipped') }
                                                            setStatusVideo={ async (payload) => {
                                                                await dispatch(GeneralActions.setStatusVideo({
                                                                    ...payload,
                                                                    post_type: 'video'
                                                                }));
                                                            } }
                                                            type="video"
                                                            is_watch_now={ false }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={10} lg={10}>
                                                { renderVideo() }
                                            </Col>
                                        </Row>
                                        <Row className="my-3">
                                            <Col xs={12} md={10} className="d-flex flex-column flex-md-row">
                                                <div className="mb-1 flex-shrink">
                                                { renderTranscriptButton() }
                                                </div>
                                                <div className="ml-md-auto d-flex flex-grow justify-content-md-end">
                                                    { renderHomeWorkButton() }
                                                </div>
                                            </Col>
                                        </Row>
                                        {  get(data, 'content.rendered') &&
                                            <>
                                                <hr/>
                                                <Row className="my-3">
                                                    <Col xs={12} md={10}>
                                                        { renderContent() }
                                                    </Col>
                                                </Row>
                                                <hr className="mb-5"/>
                                            </>
                                        }
                                        <Row>
                                            <Col xs={12} md={10}>
                                                <div className="mb-3">
                                                    <CommentForm id={ props.ID }/>
                                                </div>
                                                <CommentList id={ props.ID}/>
                                            </Col>
                                        </Row>
                                        </>
                                    )}
                                </div>
                            </div>
                        </main>
                    </Row>
                </Container>
        </>
    );
};

export default withReducer('videoApp', reducer)(Video);