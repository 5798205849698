import React, {createRef, useEffect, useState} from "react";
import { Alert, Button, Container, Col, Row, Spinner } from "react-bootstrap";
import withReducer from "../../store/withReducer";
import qs from "qs";
import reducer from "./store/reducers";
import { Link } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import { AiFillCheckSquare } from 'react-icons/ai';
import Form from '../Hero/components/Form.jsx';

const Register = ({location}) => {

    const container = createRef();
    const dispatch = useDispatch();

    const error = useSelector(({ registerApp }) => registerApp.register.error);
    const isLoad = useSelector(({ registerApp }) => registerApp.register.isLoad);

    useEffect(() => {

        return () => {
            dispatch(Actions.clear());
        }
    }, [dispatch]);

    const [form, setForm] = useState({
        username: '',
        email: '',
        password: '',
        password_confirm: '',
    });

    const [error_confirm, setError_confirm] = useState(false);
    const [error_password, setError_password] = useState(false);

    const handlerForm = ({ target }) => {
        setError_confirm(false);
        setError_password(false);

        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        if (form.password !== form.password_confirm) {
            return setError_confirm(true);
        }

        if (form.password.length < 6) {
            return setError_password(true)
        }

        dispatch(Actions.registerUser({
            username: form.username,
            email: form.email,
            password: form.password
        }));

    };

    const validate = () => (
        form.username.length
        && form.email.length
        && form.password.length
        && form.password_confirm.length
    );

    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={12} lg={6} className="my-4 my-md-8 pr-sm-6 px-2">
                    <Link to="/" className="header__logo mr-0 mr-lg-4" title="Home">
                        <span className="sr-only">Startup School for Seniors</span>
                    </Link>
                    <h1 className="mb-4 h4">Signup to Startup School for Seniors.</h1>
                    <p>Dear visitor, our sign-up is closed right now and will open up again when our next course starts!</p>
                    <p>You can leave your email address in the form below and we will notify you when a new course wil start.</p>
                    <Form/>
                </Col>
                <Col xs={12} lg={6} className="my-4 my-md-8 d-flex justify-content-center align-items-center flex-column px-2 px-sm-8 text-left">
                    <p className="lead mb-4">The Startup School for Seniors course has been designed for anyone over 50 who wants to run a business but doesn’t know where to start.</p>
                    <ul className="list-unstyled mb-3">
                        <li className="lead mb-1"><span className="text-info mr-2"><AiFillCheckSquare size={32}/></span> Comprehensive business startup course</li>
                        <li className="lead mb-1"><span className="text-info mr-2"><AiFillCheckSquare size={32}/></span> Qualified mentors to guide your journey</li>
                        <li className="lead mb-1"><span className="text-info mr-2"><AiFillCheckSquare size={32}/></span> Exploration and collaboration call</li>
                        <li className="lead mb-1"><span className="text-info mr-2"><AiFillCheckSquare size={32}/></span> Supportive private Facebook group</li>
                    </ul>
                    <Link to="https://startupschoolforseniors.com" target="_blank" className="btn btn-outline-info btn-large">Learn more</Link>
                </Col>
            </Row>
        </Container>
    )
};

export default withReducer('registerApp', reducer)(Register);