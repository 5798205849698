import * as Actions from '../actions';

const initialState = {
    error: null,
    isLoad: false,
};

const loginReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_WITH_PARAMS_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.SET_LOGIN_LOAD: {
            return {
                ...state,
                isLoad: action.payload
            };
        }
        case Actions.CLEAR: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default loginReducer;
