import * as Actions from '../actions';

const initialState = {
    error: false,
    data: [],
    companyInfo: null,
};

const listingReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMPANY: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_COMPANY_INFO: {
            return {
                ...state,
                companyInfo: action.payload
            };
        }
        case Actions.GET_COMPANY_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.CLEAR_COMPANY: {
            return {
                ...state,
                data: initialState.data,
                error: initialState.error
            };
        }
        case Actions.CLEAR_COMPANY_INFO: {
            return {
                ...state,
                companyInfo: initialState.companyInfo,
            };
        }
        default: {
            return state;
        }
    }
};

export default listingReducer;
