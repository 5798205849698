import axios from "axios";
import qs from "qs";
import { authWithToken } from "./auth.actions";
import * as CONSTANTS from '../../constants';

export function setStatusVideo({ post_id, action, field_name, post_type }) {
    return (dispatch) =>
        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/course`, qs.stringify({
            post_id,
            action,
            field_name,
            post_type,
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(() => {
                return dispatch(authWithToken());
            })
}
