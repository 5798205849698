import axios from 'axios';
import * as userActions from '../../../../store/actions';
import * as CONSTANTS from '../../../../constants';

export const LOGIN_WITH_PARAMS_ERROR = '[LOGIN APP] LOGIN WITH PARAMS ERROR';
export const SET_LOGIN_LOAD = '[LOGIN APP] SET LOGIN LOAD';
export const CLEAR = '[LOGIN APP] CLEAR';

export function loginWithParams({ username, password }) {

    return (dispatch) => {
        dispatch(clear());
        dispatch(setLoginLoad(true));

        axios.post(CONSTANTS.SITE_URL + `/wp-json/jwt-auth/v1/token?username=${ username }&password=${ password }`)
            .then((response) => {
                axios.defaults.headers['Authorization'] = `Bearer ${ response.data.token }`;
                localStorage.setItem('access_token', response.data.token);

                dispatch(userActions.authWithToken());
                dispatch(setLoginLoad(false));
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_WITH_PARAMS_ERROR,
                    payload: error.response.data.message
                });
                dispatch(setLoginLoad(false));
            })
    }

}

export function setLoginLoad(payload) {

    return (dispatch) => {
        dispatch({
            type: SET_LOGIN_LOAD,
            payload,
        });
    }
}

export function clear() {

    return (dispatch) => {
        dispatch({
            type: CLEAR
        });
    }
}
