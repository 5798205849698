import * as Actions from '../actions';

const initialState = {
    data: [],
    error: false,
    isLoad: false,
    active_id: null,
    per_page: 10,
    box: 'inbox'
};

const messagesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MESSAGES: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.ERROR_MESSAGES: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.SET_ACTIVE_ID_MESSAGE: {
            return {
                ...state,
                active_id: action.payload
            };
        }
        case Actions.SET_PER_PAGE_MESSAGE: {
            return {
                ...state,
                per_page: action.payload
            };
        }
        case Actions.SET_BOX_MESSAGE: {
            return {
                ...state,
                box: action.payload
            };
        }
        case Actions.LOAD_MESSAGES: {
            return {
                ...state,
                isLoad: action.payload
            };
        }
        case Actions.CLEAR_MESSAGES: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default messagesReducer;
