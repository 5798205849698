import React, { useState } from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import SideMenu from "./SideMenu";

const ProfileChangePassword = () => {

    const dispatch = useDispatch();

    const error = useSelector(({ profileApp }) => profileApp.changePassword.error);
    const success = useSelector(({ profileApp }) => profileApp.changePassword.success);

    const [form, setForm] = useState({
        password: '',
        password_confirm: '',
    });

    const [error_confirm, setError_confirm] = useState(false);
    const [error_password, setError_password] = useState(false);

    const handlerForm = ({ target }) => {
        setError_confirm(false);
        setError_password(false);

        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        if (form.password !== form.password_confirm) {
            return setError_confirm(true);
        }

        if (form.password.length < 6) {
            return setError_password(true)
        }

        dispatch(Actions.changePassword({
            password: form.password
        }))
    };

    const validate = () => (
        form.password.length
        && form.password_confirm.length
    );

    return (
        <>
            <Container fluid>
                <Row>
                    <SideMenu/>
                    <main role="main" className="col-md-9 ml-sm-auto">
                        <div className="academy-dash__welcome">
                            <Row className="no-gutters">
                                <Col xs={ 12 } md={ 9 } className="card">
                                    <div className="card-header bg-transparent px-md-4 py-md-3 p-1">
                                        <div className="row d-flex align-items-center">
                                            <div className="col">
                                                <h4 className="m-0 d-flex align-items-center"><span>Change your password</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-1 p-md-4">
                                        <Row className="justify-content-md-center">
                                            <Col xs={ 12 } className="d-flex align-items-center">
                                                <Form
                                                    className="w-100"
                                                    onSubmit={ handlerSubmit }
                                                >
                                                    {
                                                        error && (
                                                            <Alert variant="danger">
                                                                { error }
                                                            </Alert>
                                                        )
                                                    }
                                                    {
                                                        success && (
                                                            <Alert variant="success">
                                                                Your password changed
                                                            </Alert>
                                                        )
                                                    }
                                                    {
                                                        error_confirm && (
                                                            <Alert variant="danger">
                                                                Your password and confirmation password do not match
                                                            </Alert>
                                                        )
                                                    }
                                                    {
                                                        error_password && (
                                                            <Alert variant="danger">
                                                                The password field must be at least 6 characters
                                                            </Alert>
                                                        )
                                                    }
                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label>New password</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            name="password"
                                                            value={ form.password }
                                                            onChange={ handlerForm }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicPasswordConfirm">
                                                        <Form.Label>Confirm new password</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            name="password_confirm"
                                                            value={ form.password_confirm }
                                                            onChange={ handlerForm }
                                                        />
                                                    </Form.Group>
                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        disabled={ !validate() }
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </main>
                </Row>
            </Container>
        </>

    )
};

export default ProfileChangePassword;
