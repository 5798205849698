import React from "react";
import { Router } from "@reach/router";
import Members from "../profile/pages/Members/Members";
import Member from "../profile/pages/Members/pages/Member/Member";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Login from "../components/Login/Login";
import Dashboard from "../profile/pages/Dashboard/Dashboard";
import Profile from "../profile/pages/Profile/Profile";
import Messages from "../profile/pages/Messages/Messages";
import Videos from "../profile/pages/Profile/components/Videos";
import Lessons from "../profile/pages/Profile/components/Lessons";
import ProfileListing from "../profile/pages/Profile/components/ProfileListing";
import Lesson from "../profile/pages/Lesson/Lesson";
import Video from "../profile/pages/Video/Video";
import Updates from "../profile/pages/Profile/components/Updates";
import PreviousUpdates from "../profile/pages/Profile/components/PreviousUpdates";
import ProfileChangePassword from "../profile/pages/Profile/components/ProfileChangePassword";
import Listing from "../profile/pages/Profile/components/Listing";
import Deals from "../profile/pages/Profile/components/Deals";
import Deal from "../profile/pages/Profile/components/Deal";
import Register from "../components/Register/Register";
import SubmitDeal from "../components/SubmitDeal";
import PasswordForgot from "../components/PasswordForgot/PasswordForgot";
// import { HashRouter as Router, Switch } from "react-router-dom"


const PagesRoute = () => {
    return (
      <>
          <Router>
                  <PrivateRoute key="Members" as={Members} path="/members/browse"/>
                  <PrivateRoute key="Member" as={Member} path="/members/:ID"/>
                  <PrivateRoute key="Dashboard" as={Dashboard} path="/dashboard"/>
                  <PrivateRoute key="Listing" as={ProfileListing} path="/listing"/>
                  <PrivateRoute key="Profile" as={Profile} path="/profile"/>
                  <PrivateRoute key="Company" as={Profile} path="/profile/company"/>
                  <PrivateRoute key="Messages" as={Messages} path="/messages"/>
                  <PrivateRoute key="Lessons" as={Lessons} path="/lessons"/>
                  <PrivateRoute key="Lesson" as={Lesson} path="/lesson/:ID"/>
                  <PrivateRoute key="Videos" as={Videos} path="/videos"/>
                  <PrivateRoute key="Video" as={Video} path="/video/:ID"/>
                  <PrivateRoute key="Updates" as={Updates} path="/updates"/>
                  <PrivateRoute key="Check-ins" as={PreviousUpdates} path="/previous-updates"/>
                  <PrivateRoute key="ProfileChangePassword" as={ProfileChangePassword} path="/profile/edit-password"/>
                  <PrivateRoute key="Listing" as={Listing} path="/profile/listing"/>
                  <PublicRoute key="Home" default as={Login} path="/login"/>
                  <PublicRoute key="Register" as={Register} path="/register"/>
                  <PublicRoute key="PasswordForget" as={PasswordForgot} path="/password-forget"/>
                  <PrivateRoute key="Deals" as={Deals} path="/offers"/>
                  <PublicRoute key="SubmitDeal" as={SubmitDeal} path="/submit-offer"/>
                  <PrivateRoute key="Deal" as={Deal} path="/offer/:ID"/>
          </Router>
      </>
    )
};

export default PagesRoute;