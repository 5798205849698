import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import * as userActions from '../store/actions';
import Loader from "./Loader";

class Auth extends Component {

    state = {
        waitAuthCheck: !!this.props.AuthCheck
    };

    componentDidMount() {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['accept'] = 'application/json';
        // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        const access_token = localStorage.getItem('access_token');

        if (access_token) {
            axios.defaults.headers['Authorization'] = `Bearer ${ access_token }`;

            this.props.loginWithToken()
                .then(() => this.setState({ waitAuthCheck: false }))
                .catch(() => this.setState({ waitAuthCheck: false }))
        } else {
            this.setState({ waitAuthCheck: false });
        }
    }

    render() {
        return this.state.waitAuthCheck ? <Loader/> : <React.Fragment children={ this.props.children }/>;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            loginWithToken: userActions.authWithToken,
        },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);