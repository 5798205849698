import axios from 'axios';
import { getComments } from "./comments.actions";
import * as CONSTANTS from '../../constants';

export const SUCCESS_COMMENT = '[COMMENT APP] SUCCESS COMMENT';
export const ERROR_COMMENT = '[COMMENT APP] ERROR COMMENT';
export const SET_LOAD_COMMENT = '[COMMENT APP] SET LOAD COMMENT';
export const CLEAR_COMMENT = '[COMMENT APP] CLEAR';

export function sendComment({ post, author_name, author_email, content, parent = false }) {

    return (dispatch) => {
        dispatch(setLoadComment(true));
        dispatch({
            type: SUCCESS_COMMENT,
            payload: false
        });
        dispatch({
            type: ERROR_COMMENT,
            payload: false
        });

        let resData = {
            post,
            author_name,
            author_url: '',
            author_email,
            content,
        };

        if (parent) {
            resData['parent'] = parent;
        }

        return axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/comments`, resData)
            .then(() => {
                dispatch({
                    type: SUCCESS_COMMENT,
                    payload: true
                });

                dispatch(setLoadComment(false));
                dispatch(getComments({ id: post, isParent: true }));
            })
            .catch((error) => {
                dispatch({
                    type: ERROR_COMMENT,
                    payload: error.response.data.message
                });
                dispatch(setLoadComment(false));
            })
    }
}

export function clearComment() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_COMMENT
        });
    }
}

export function setLoadComment(isLoad) {

    return (dispatch) => {
        dispatch({
            type: SET_LOAD_COMMENT,
            payload: isLoad
        });
    }
}
