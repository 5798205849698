import {Provider} from "react-redux";
import PagesRoute from "./components/PagesRoute";
import store from "./store";
import Auth from "./components/Auth"
import {MobileTabs} from "./components/MobileTabs"
import React from "react"


const App = () => {

  const AuthCheck = false;

  return (
      <Provider store={store}>
        <Auth AuthCheck={AuthCheck}>
            <PagesRoute/>
            <MobileTabs/>
        </Auth>
      </Provider>
  )
};


export default App;
