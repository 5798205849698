import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_MESSAGES = '[MESSAGES APP] GET MESSAGES';
export const ERROR_MESSAGES = '[MESSAGES APP] ERROR MESSAGES';
export const LOAD_MESSAGES = '[MESSAGES APP] LOAD MESSAGES';
export const SET_ACTIVE_ID_MESSAGE = '[MESSAGES APP] SET ACTIVE ID MESSAGE';
export const SET_PER_PAGE_MESSAGE = '[MESSAGES APP] SET PER PAGE MESSAGE';
export const SET_BOX_MESSAGE = '[MESSAGES APP] SET BOX MESSAGE';
export const CLEAR_MESSAGES = '[MESSAGES APP] CLEAR MESSAGES';

export function getMessages({ load } = {}) {

    return (dispatch, getState) => {
        const { per_page, box } = getState().messagesApp.messages;

        dispatch(errorMessages(false));
        if (!load) {
            dispatch(loadMessages(true));
        }

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/messages`, {
            params: {
                per_page,
                box,
            }
        })
            .then((response) => {
                dispatch(loadMessages(false));

                dispatch({
                    type: GET_MESSAGES,
                    payload: response.data
                });

                if (!response.data.length) {
                    dispatch(errorMessages(true));
                }
            })
            .catch(() => {
                dispatch(errorMessages(true));
                dispatch(loadMessages(false));
            })
    }
}

export function clearMessages() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_MESSAGES
        });
    }
}

export function errorMessages(isError) {

    return (dispatch) => {
        dispatch({
            type: ERROR_MESSAGES,
            payload: isError
        });
    }
}

export function loadMessages(isLoad) {

    return (dispatch) => {
        dispatch({
            type: LOAD_MESSAGES,
            payload: isLoad
        });
    }
}

export function setPerPageMessage(per_page) {

    return (dispatch) => {
        dispatch({
            type: SET_PER_PAGE_MESSAGE,
            payload: per_page
        });
    }
}

export function setBoxMessage(box) {

    return (dispatch) => {
        dispatch({
            type: SET_BOX_MESSAGE,
            payload: box
        });
    }
}

export function setActiveIdMessage(id) {

    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_ID_MESSAGE,
            payload: id
        });
    }
}