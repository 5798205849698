import * as Actions from '../actions';

const initialState = {
    error: null,
    isLoad: false,
};

const registerReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.REGISTER_USER_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.SET_REGISTER_LOAD: {
            return {
                ...state,
                isLoad: action.payload
            };
        }
        case Actions.CLEAR: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default registerReducer;
