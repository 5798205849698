import React from "react";
import { Link } from "@reach/router"
import {BsChevronRight} from 'react-icons/bs';
import {TiUser} from 'react-icons/ti';
import {AiFillDashboard} from 'react-icons/ai';
import {RiCalendarCheckFill} from 'react-icons/ri';
import {MdMessage} from 'react-icons/md';
import {ImLibrary} from 'react-icons/im';
import {BsTagFill} from 'react-icons/bs';
import {FaUsers} from 'react-icons/fa';
import { useSelector } from "react-redux";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import {useDispatch} from "react-redux";
import * as Actions from '../../../../store/actions';
import { SvgLogo } from "../../../../components/svgs/index"

const SideMenu = () => {

    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => auth.user);

    const logoutuser = (e) => {
        e.preventDefault();

        dispatch(Actions.logout());

    };

    return (
        <>
            <div id="sidebarMenu"
                 className="d-none col-md-3 col-lg-3 d-lg-block bg-light sidebar">
                <div className="sidebar-sticky pt-3">
                    <nav className="header-menu flex-column">
                        <ul className="header-list list">
                            <li className="header-item">
                                <a href="/" className="sidebar-header-logo header-link">
                                    <Link to="/" className="header__logo mr-0 mr-lg-4" title="Home">
                                        <span className="sr-only">Startup School for Seniors</span>
                                        <SvgLogo/>
                                    </Link>
                                    <span className="sr-only">Startup School for Seniors</span>
                                </a>
                            </li>
                            { user && (
                              <>
                                <li className="header-item">
                                    <Link
                                        className="header-link"
                                        to={'/dashboard'}>
                                        <div className="icon">
                                            <AiFillDashboard/>
                                        </div>

                                        <div className="text">
                                            <span>Dashboard</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="header-item dropdown dropright">
                                    <Link
                                        
                                        className="header-link dropdown-toggle"
                                        data-toggle="dropdown"
                                        to={'/profile'}>
                                        <div className="icon">
                                            <TiUser/>
                                        </div>
                                        <div className="text">
                                            <span>My Profile</span>
                                            <BsChevronRight/>
                                        </div>
                                    </Link>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link
                                                className="dropdown-item small"
                                                
                                                to={'/profile'}>
                                                User Details
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item small"
                                                
                                                state={{group_id: 2}}
                                                to={'/profile/company'}>
                                                Company Details
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item small"
                                                
                                                to={'/profile/edit-password'}>
                                                Edit password
                                            </Link>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item small"
                                                onClick={logoutuser}
                                                >
                                                Sign out
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                                <li className="header-item">
                                    <Link
                                        
                                        className="header-link"
                                        to={'/lessons'}>
                                        <div className="icon">
                                            <AiFillDashboard/>
                                        </div>
                                        <div className="text">
                                            <span>Sessions</span>
                                        </div>
                                    </Link>
                                </li>
                                  <li className="header-item dropdown dropright">
                                      <Link
                                          
                                          className="header-link dropdown-toggle"
                                          data-toggle="dropdown"
                                          to={'/previous-updates'}>
                                          <div className="icon">
                                              <RiCalendarCheckFill/>
                                          </div>
                                          <div className="text">
                                              <span>My Updates</span>
                                              <BsChevronRight/>
                                          </div>
                                      </Link>
                                      <ul className="dropdown-menu">
                                          <li>
                                              <Link
                                                  className="dropdown-item small"
                                                  
                                                  to={'/previous-updates'}>
                                                  Previous Updates
                                              </Link>
                                          </li>
                                          <li>
                                              <Link
                                                  className="dropdown-item small"
                                                  
                                                  to={'/updates'}>
                                                  My Updates
                                              </Link>
                                          </li>
                                      </ul>
                                  </li>
                                <li className="header-item">
                                    <Link
                                        
                                        className="header-link"
                                        to={'/members/browse'}>
                                        <div className="icon">
                                            <FaUsers/>
                                        </div>
                                        <div className="text">
                                            <span>Members</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="header-item">
                                    <Link
                                        
                                        className="header-link"
                                        to={'/offers'}>
                                        <div className="icon">
                                            <BsTagFill/>
                                        </div>
                                        <div className="text">
                                            <span>Offers</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="header-item">
                                    <Link
                                        
                                        className="header-link"
                                        to={'/messages'}>
                                        <div className="icon">
                                            <MdMessage/>
                                        </div>
                                        <div className="text">
                                            <span>Messages</span>
                                        </div>
                                    </Link>
                                </li>
                                <li className="header-item">
                                    <a
                                        className="header-link"
                                        href='https://drive.google.com/drive/folders/1AZKmDz---uIrRIujFtrTjpJm8IlzWfWI'>
                                        <div className="icon">
                                            <ImLibrary/>
                                        </div>
                                        <div className="text">
                                            <span>Library of Good Things</span>
                                        </div>
                                    </a>
                                </li>
                              </>
                           )}
                        </ul>
                    </nav>
                </div>
            </div>
       </>
    )
};

export default withReducer('profileApp', reducer)(SideMenu);
