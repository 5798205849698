import React, { useEffect } from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import get from 'lodash/get';
import has from 'lodash/has';
import ProfileVideosItem from "./ProfileVideosItem";
import { is_state, isRelease } from "../../../../constants";
import * as GeneralActions from "../../../../store/actions";

const ProfileVideos = () => {

    const dispatch = useDispatch();

    const error = useSelector(({ profileApp }) => profileApp.videos.error);
    const user_acf = useSelector(({ auth }) => auth.user.acf);
    const data = useSelector(({ profileApp }) => profileApp.videos.data);

    useEffect(() => {
        dispatch(Actions.getVideos());

        return () => {
            dispatch(Actions.clearVideos());
        }
    }, [ dispatch ]);

    const renderVideoItem = (item) => (
        <ProfileVideosItem
            item={ item }
            is_complete_state={ (param) => is_state(param, user_acf, 'video_complete') }
            is_skipped_state={ (param) => is_state(param, user_acf, 'video_skipped') }
            setStatusVideo={ async (payload) => {
                await dispatch(GeneralActions.setStatusVideo({
                    ...payload,
                    post_type: 'video'
                }));
            } }
            type="video"
            key={ item.id }
        />
    );

    return (
      <>
        <Container>
            <Row className="d-flex">
                <Col xs={ 12 }>
                {
                    error && (
                        <Alert variant="danger" className="mt-4 mb-0">
                            Error Videos
                        </Alert>
                    )
                }
                {
                    Boolean(data.length) && data.map((item, i) => {

                        if (has(item, 'acf.release_date')) {

                            if (isRelease(get(item, 'acf.release_date'))) {
                                return renderVideoItem(item);
                            }

                            return null;
                        }

                        return renderVideoItem(item);
                    })
                }
                </Col>
            </Row>
        </Container>
      </>
    )
};

export default ProfileVideos;