import React, { useState } from "react";
import { Alert, Button, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Actions from '../store/actions';
import get from 'lodash/get';
import { navigate } from "@reach/router"

const initialMessage = 'First message';

const SendMessage = ({ name, handleClose, show }) => {
    const dispatch = useDispatch();

    const [form, setForm] = useState({
        message: '',
        subject: '',
    });

    const [error, setError] = useState(false);

    const handlerForm = ({ target }) => {
        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };


    // const handlerSubmit = () => {
    //     setError(false);
    //
    //     dispatch(Actions.sendNewMessage({
    //         message: form.message,
    //     }))
    //         .then((res) => {
    //             const id = get(res.data, '[0].id');
    //
    //             history.push({
    //                 pathname: '/dashboard/messages',
    //                 state: { id }
    //             });
    //         })
    //         .catch(() => setError(true));
    // };

    const handlerSubmit = () => {
        setError(false);

        dispatch(Actions.sendNewMessage({
            message: form.message,
            subject: form.subject
        }))
            .then((res) => {
                const id = get(res.data, '[0].id');
                // console.log(res);
                navigate(`/messages`, { id } )
            })
            .catch(() => setError(true));
    };

    const validateSend = () => form.message.length;

    return (
        <Container>
            <Row>
                <Modal show={ show } onHide={ handleClose }>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            error && (
                                <Alert variant="danger" className="mt-3 mb-3">
                                    Error send message
                                </Alert>
                            )
                        }
                        <Form.Control
                            as="textarea"
                            rows="1"
                            className="mb-2"
                            name="subject"
                            placeholder="Subject"
                            value={ form.subject }
                            onChange={ handlerForm }
                        />
                        <Form.Control
                            as="textarea"
                            rows="5"
                            name="message"
                            placeholder="Message"
                            value={ form.message }
                            onChange={ handlerForm }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={ handleClose }
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            disabled={ !validateSend() }
                            onClick={ handlerSubmit }
                        >
                            Send
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
        </Container>
    )
};

export default SendMessage;
