import axios from 'axios';
import * as CONSTANTS from '../../../../../constants';

export const GET_LESSON = '[LESSON APP] GET LESSON';
export const SET_LESSON_ERROR = '[LESSON APP] GET LESSON ERROR';
export const CLEAR_LESSON = '[LESSON APP] CLEAR';

export function getLesson({ id }) {

    return (dispatch) => {
        dispatch({
            type: SET_LESSON_ERROR,
            payload: false
        });

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/lesson/${ id }?_embed`)
            .then((res) => {
                dispatch({
                    type: GET_LESSON,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: SET_LESSON_ERROR,
                    payload: true
                });
            });

    }
}

export function clearLesson() {

    return (dispatch) => dispatch({ type: CLEAR_LESSON });
}