import * as Actions from '../actions';

const initialState = {
    load: false,
    success: false,
    error: false,
};

const commentReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_LOAD_COMMENT: {
            return {
                ...state,
                load: action.payload
            };
        }
        case Actions.SUCCESS_COMMENT: {
            return {
                ...state,
                success: action.payload
            };
        }
        case Actions.ERROR_COMMENT: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.CLEAR_COMMENT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default commentReducer;
