import * as Actions from '../actions';
import getWeeekNumber from '../../../../utils/weekNumber';

const initialState = {
  data: {},
  isWeekInApi: false
};

const dealReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_WEEKS: {

            const week = getWeeekNumber(new Date());
            const activeWeeks = {};

            action.payload.acf.check_in.forEach(({week}) => activeWeeks[+week] =  true)

            return {
                ...state,
                data: activeWeeks,
                isWeekInApi: activeWeeks[week]
            };
        }
        default: {
            return state;
        }
    }
};

export default dealReducer;
