import * as Actions from '../actions';

const initialState = {
    user: null,
};

const message = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USER_AUTH: {
            return {
                ...state,
                user: action.payload
            };
        }
        case Actions.LOGOUT_AUTH: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default message;