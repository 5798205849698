import React, {useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import MessagesNew from "./MessagesNew";
import UserDialog from "./UserDialog";
import {useSelector} from "react-redux";

import Mail from '../media/mail.svg';
import '../style/messages.css';

const DialogAside = ({ setListActive }) => {

    const active_id = useSelector(({messagesApp}) => messagesApp.messages.active_id);
    const load = useSelector(({ messagesApp }) => messagesApp.userDialog.load);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="dialog-aside">
            <div className="dialog-aside__top d-flex">
                <Button
                    variant="outline-primary"
                    size="sm"
                    className="mr-4 d-block d-md-none"
                    onClick={() => setListActive(true)}
                >
                    Back
                </Button>
                {
                    load && <Spinner animation="border" style={{ marginTop: 10 }} size="sm" />
                }
            </div>
            {
                active_id ? (
                    <UserDialog active_id={active_id}/>
                ) : (
                    <div className="message-notif">
                        <img src={Mail} width={50} alt=""/>
                    </div>
                )
            }
        </div>
    )
};

export default DialogAside;