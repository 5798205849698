import { combineReducers } from 'redux';
import profile from './profile.reducer';
import changePassword from './changePassword.reducer';
import listing from './listing.reducer';
import videos from './videos.reducer';
import deals from './deals.reducer';
import deal from './deal.reducer';
import lessons from './lessons.reducer';

const reducer = combineReducers({
    profile,
    changePassword,
    listing,
    videos,
    lessons,
    deals,
    deal
});

export default reducer;
