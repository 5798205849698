import React, {useState, useRef} from "react"
import {Col, Container, Row, FormControl, FormGroup} from "react-bootstrap";
import {useForm} from "react-hook-form";
import axios from "axios";
import formurlencoded from "form-urlencoded";
import {useSelector} from "react-redux";
import CharacterCounter from 'react-character-counter'
import * as CONSTANTS from '../constants';

const SubmitDeal = (props) => {

    const user = useSelector(({ auth }) => auth.user);
    const [confirmationHTML, setConfirmationHTML] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputDetails, setInputDetails] = useState('');
    const [inputContent, setinputContent] = useState('');

    const {register, handleSubmit, errors} = useForm();

    let btnRef = useRef();

    function handleTitleChange(event) {
        setInputTitle(event.target.value);
    }

    function handleDetailsChange(event) {
        setInputDetails(event.target.value);
    }

    function handleContentChange(event) {
        setinputContent(event.target.value);
    }

    const onSubmit = values => {

        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        }

        let form_url = CONSTANTS.SITE_URL + `/wp-json/gf_entry/v2/`;
        const { name, email, offer_email, title, content, category, link, details } = values;

        const entry = {
            name: name,
            email: email,
            offer_email: offer_email,
            title: title,
            content: content,
            category: category,
            link: link,
            details: details,
            form_id: 5,
        };

        const sendData = async () => {
            // setIsLoading(true);

            await axios({
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                method: 'post',
                url: form_url,
                responseType: 'json',
                data: formurlencoded(entry),
            })
                .then(async function (response) {
                    try {
                        let gravity_response = JSON.parse(response.data.body); // this is how you parse a string into JSON

                        if (gravity_response.is_valid) {
                            setConfirmationHTML(gravity_response.confirmation_message);
                        } else {
                            setErrorMessage('There was an error while processing your contact request.');

                            if(btnRef.current){
                                btnRef.current.removeAttribute("disabled");
                            }

                        }

                    } catch (ex) {
                        // console.error(ex);
                        if(btnRef.current){
                            btnRef.current.removeAttribute("disabled");
                        }
                    }
                });
        };

        sendData();
    };

    return (
        <>
            <Container>
                <Row>
                    <Col xs={ 12 } sm={ 10 } xl={ 8 } >
                        {errorMessage && (
                            <>
                                <p className="form__comfirmation-text alert alert-danger" >
                                    There has been a problem: please check your email and try again.
                                </p>
                            </>
                        )}
                        {!confirmationHTML ? (
                            <>
                                <h1 className="page__title">
                                    Submit an offer
                                </h1>
                                <p className="mb-3">
                                    Would you like to offer something to our members? A discount or do you have a service that our community can benefit from? Please fill in the information below about your offer.
                                </p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="form-group col-12 col-sm-12">
                                            <label className="label"  htmlFor="contactName">
                                                Name (First, Last) <small>(For our reference only -- not displayed inside offer)</small>
                                            </label>
                                            <input
                                                name="name"
                                                ref={register({
                                                    required: "Please fill in your name"
                                                })}
                                                className="form-control"
                                                placeholder=""
                                                id="contactName"

                                            />
                                            {errors.contactName && (
                                                <p className="text-error">{errors.contactName.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12 col-sm-12">
                                            <label className="label"  htmlFor="contactEmail">
                                                Email <small>(For our reference only -- not displayed inside deal)</small>
                                            </label>
                                            <input
                                                name="email"
                                                ref={register({
                                                    required: "Please fill in your email"
                                                })}
                                                className="form-control"
                                                placeholder=""
                                                id="contactEmail"
                                            />
                                            {errors.contactEmail && (
                                                <p className="text-error">{errors.contactEmail.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="title">
                                                Offer Title
                                            </label>
                                            <CharacterCounter value={inputTitle} maxLength={50} >
                                                <input
                                                    name="title"
                                                    ref={register({
                                                        required: "Please fill in your offer title"
                                                    })}
                                                    onChange={handleTitleChange}
                                                    className="form-control"
                                                    placeholder=""
                                                    id="title"
                                                    maxLength={50}
                                                />
                                                {errors.title && (
                                                    <p className="text-error">{errors.title.message}</p>
                                                )}
                                            </CharacterCounter>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="category">
                                                Offer Category (e.g. Tools, Marketing, Web Development)
                                            </label>
                                            <input
                                                name="category"
                                                ref={register({
                                                    required: "Please fill in your offer category"
                                                })}
                                                className="form-control"
                                                placeholder=""
                                                id="category"

                                            />
                                            {errors.category && (
                                                <p className="text-error">{errors.category.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="link">
                                                Offer Website (Where people can find out more about the offer)
                                            </label>
                                            <input
                                                name="link"
                                                type="url"
                                                placeholder="https://example.com"
                                                pattern="https://.*" size="30"
                                                className="form-control"
                                                id="link"
                                                ref={register}

                                            />
                                            <small className="form-text text-muted">
                                                * Not Required
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="offer_email">
                                                Offer Contact Email (Who can people contact to redeem this offer?)
                                            </label>
                                            <input
                                                name="offer_email"
                                                className="form-control"
                                                id="offer_email"
                                                ref={register}

                                            />
                                            <small className="form-text text-muted">
                                                * Not Required
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="content">
                                                Tell us about the company or person giving away the offer
                                            </label>
                                            <CharacterCounter value={inputContent} maxLength={200} >
                                                <textarea
                                                    name="content"
                                                    ref={register({
                                                        required: "Please fill in your offer information"
                                                    })}
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={handleContentChange}
                                                    id="content"
                                                    maxLength={100}
                                                />
                                            </CharacterCounter>
                                            {errors.content && (
                                                <p className="text-error">{errors.content.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label className="label"  htmlFor="details">
                                                What are you giving away exactly?
                                            </label>
                                            <CharacterCounter value={inputDetails} maxLength={100} >
                                                <textarea
                                                    name="details"
                                                    ref={register({
                                                        required: "Please fill in your offer details"
                                                    })}
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={handleDetailsChange}
                                                    id="details"
                                                    maxLength={100}
                                                />
                                            </CharacterCounter>
                                            {errors.details && (
                                                <p className="text-error">{errors.details.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <button ref={btnRef} className="btn btn-secondary request-button" type="submit">
                                            Send
                                        </button>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <>
                                <h1 className="page__title">
                                    We have received your offer
                                </h1>
                                <p className="form__comfirmation-text" dangerouslySetInnerHTML={{__html: confirmationHTML}}/>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>

    );

}


export default SubmitDeal;
