import * as Actions from '../actions';

const initialState = {
    data: [],
    error: false,
};

const usersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_USERS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.ERROR_USERS: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.CLEAR_USERS: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default usersReducer;
