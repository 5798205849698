import React from "react";
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import moment from 'moment';
import {useDispatch} from "react-redux";
import * as Actions from '../store/actions';

const MessagesList = ({ data, user, active_id, setListActive }) => {
    const dispatch = useDispatch();

    const getInterlocutor = (recipients) => {
        return get(recipients, findKey(recipients, (el) => el.user_id !== user.id)) || {};
    };

    return (
        <div className="message-aside-list">
            {
                data.map((item) => {
                    const interlocutor = getInterlocutor(item.recipients);

                    return (
                        <div
                            className={`message${active_id === item.id ? ' active' : ''}`}
                            key={item.id}
                            onClick={() => {
                                dispatch(Actions.setActiveIdMessage(item.id));
                                setListActive(false);
                            }}
                        >
                            <div className="message__name">
                                { item.subject.rendered }
                            </div>
                            <div className="message__info">
                                { item.excerpt.rendered }
                            </div>
                            <div className="message__date">
                                { moment(item.date).format('DD/MM/YYYY HH:mm') }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
};

export default MessagesList;