import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_USER_DIALOG = '[USER DIALOG APP] GET USER DIALOG';
export const GET_USER_MESSAGES = '[USER DIALOG APP] GET USER MESSAGES';
export const ERROR_USER_DIALOG = '[USER DIALOG APP] ERROR USER DIALOG';
export const LOAD_USER_DIALOG = '[USER DIALOG APP] LOAD USER DIALOG';
export const LOAD_DIALOG = '[USER DIALOG APP] LOAD DIALOG';
export const CLEAR_USER_DIALOG = '[USER DIALOG APP] CLEAR USER DIALOG';

export function getUserDialog({ id }) {

    return (dispatch) => {
        dispatch(errorUserDialog(false));
        dispatch(loadDialog(true));

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/messages/${ id }`)
            .then((response) => {
                dispatch({
                    type: GET_USER_DIALOG,
                    payload: response.data[0]
                });
                dispatch(getUserMessages());

                dispatch(loadDialog(false));
            })
            .catch(() => {
                dispatch(errorUserDialog(true));
                dispatch(loadDialog(false));
            })
    }
}

export function getUserMessages({ per_page = 15, load = false } = {}) {

    return (dispatch, getState) => {
        const { id } = getState().auth.user;
        let thread = false;
        if (getState().messagesApp.userDialog.data) {
            thread = getState().messagesApp.userDialog.data.id;
        }

        if (!thread) return false;

        if (load) dispatch(loadUserDialog(true));

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/messages/${ id }`, {
            params: {
                thread,
                per_page
            }
        })
            .then((res) => {
                dispatch({
                    type: GET_USER_MESSAGES,
                    payload: res.data.messages
                });
                dispatch(loadUserDialog(false))
            })
            .catch(() => dispatch(loadUserDialog(false)))
    }
}

export function sendMessage({ message }) {

    return (dispatch, getState) => {
        const { id } = getState().messagesApp.userDialog.data;

        dispatch(errorUserDialog(false));

        return axios.post(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/messages?message=${ message }&id=${ id }`)
            .catch(() => {
                dispatch(errorUserDialog(true));
            })
    }
}

export function clearUserDialog() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_USER_DIALOG
        });
    }
}

export function loadUserDialog(isLoad) {

    return (dispatch) => {
        dispatch({
            type: LOAD_USER_DIALOG,
            payload: isLoad
        });
    }
}

export function loadDialog(isLoad) {

    return (dispatch) => {
        dispatch({
            type: LOAD_DIALOG,
            payload: isLoad
        });
    }
}

export function errorUserDialog(isError) {

    return (dispatch) => {
        dispatch({
            type: ERROR_USER_DIALOG,
            payload: isError
        });
    }
}