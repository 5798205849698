import * as Actions from '../actions';

const initialState = {
    error: false,
    data: [],
};

const videosReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_VIDEOS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_VIDEOS_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.CLEAR_VIDEOS: {
            return {
                ...state,
                data: initialState.data,
                error: initialState.error
            };
        }
        default: {
            return state;
        }
    }
};

export default videosReducer;
