import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_LESSONS = '[LESSONS APP] GET LESSONS';
export const GET_LESSONS_ERROR = '[LESSONS APP] GET LESSONS ERROR';
export const CLEAR_LESSONS = '[LESSONS APP] CLEAR';

export function getLessons() {

    return (dispatch, getState) => {
        const { acf } = getState().auth.user;

        dispatch({
            type: GET_LESSONS_ERROR,
            payload: false
        });

        let cohort_id = acf.cohort_id ? acf.cohort_id : 667;

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/lesson?_embed`, {
            params: {
                cohort: cohort_id
            }
        })
            .then((res) => {
                dispatch({
                    type: GET_LESSONS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: GET_LESSONS_ERROR,
                    payload: true
                });
            })
    }
}

export function clearLessons() {

    return (dispatch) => dispatch({type: CLEAR_LESSONS});
}
