import React, { useState } from "react";
import { Link } from "@reach/router";


const PostStatus = ({ item_id, is_complete_state, is_skipped_state, setStatusVideo, is_watch_now = true, type, is_skip_show = true }) => {

    const [isLoadComplete, setIsLoadComplete] = useState(false);
    const [isLoadSkipped, setIsLoadSkipped] = useState(false);

    return (
        <>
            {
                is_watch_now && is_complete_state(item_id) === false && is_skipped_state(item_id) === false && (
                    <Link
                        to={ type === 'video' ? `/video/${item_id}` : `/lesson/${item_id}` }
                        className="btn btn-primary"
                        disabled={is_skipped_state(item_id)}
                    >
                        { type === 'video' ? 'Watch now' : 'Go to lesson' }
                    </Link>
                )
            }
            {
                is_skipped_state(item_id) === false && (
                    <button
                        onClick={ async () => {
                            setIsLoadComplete(true);
                            if (is_complete_state(item_id)) {
                                await setStatusVideo({ post_id: String(item_id), action: 'remove', field_name: type + '_complete' });
                                setIsLoadComplete(false);

                                return;
                            }

                            await setStatusVideo({ post_id: String(item_id), action: 'add', field_name: type + '_complete' });
                            setIsLoadComplete(false);
                        } }
                        className="btn btn-outline-primary "
                        disabled={isLoadComplete || is_skipped_state(item_id)}
                    >
                        { isLoadComplete && <span style={{ marginBottom: 2 }} className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/> }
                        { is_complete_state(item_id) ? 'Completed' : 'Mark as complete' }
                    </button>
                )
            }
            { is_complete_state(item_id) && type === 'lesson' && (
                <Link className="w-100 d-block text-center text-danger" to={'/updates'}>Send us your Weekly check-in</Link>
            )}
            {
                is_skip_show && is_complete_state(item_id) === false && (
                    <button
                        className="btn btn-link text-danger"
                        onClick={ async () => {
                            setIsLoadSkipped(true);
                            if (is_skipped_state(item_id)) {
                                await setStatusVideo({ post_id: String(item_id), action: 'remove', field_name: type + '_skipped' });
                                setIsLoadSkipped(false);

                                return;
                            }

                            await setStatusVideo({ post_id: String(item_id), action: 'add', field_name: type + '_skipped' });
                            setIsLoadSkipped(false);
                        } }
                        disabled={isLoadSkipped}
                    >
                        { isLoadSkipped && <span style={{ marginBottom: 2 }} className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/> }
                        { is_skipped_state(item_id) ? 'Skipped - undo' : 'Skip' }
                    </button>
                )
            }
        </>
    )
};

export default PostStatus;
