import * as Actions from '../actions';

const initialState = {
    data: [],
    error: false,
    members_max: 0,
    per_page: 12,
    search: '',
};

const membersReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MEMBERS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.ERROR_MEMBERS: {
            return {
                ...state,
                error: action.payload
            };
        }
        case Actions.SET_MEMBERS_PERPAGE: {
            return {
                ...state,
                per_page: action.payload
            };
        }
        case Actions.SET_MEMBERS_SEARCH: {
            return {
                ...state,
                search: action.payload
            };
        }
        case Actions.SET_MEMBERS_MAX: {
            return {
                ...state,
                members_max: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default membersReducer;
