const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export default (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const month1 = months[new Date(d1).getMonth()];
    const month2 = months[new Date(d2).getMonth()];
    const year = d2.getFullYear();
    const day1 = d1.getDate();
    const day2 = d2.getDate();
    return `${month1} ${day1 < 10 ? '0' + day1 : day1}-${month2} ${day2 < 10 ? '0' + day2 : day2}, ${year}`
}