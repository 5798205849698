import React, { useState } from "react";
import { Alert, Button, Col, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import get from 'lodash/get';
import find from "lodash/find";

const ProfileListing = ({ fields, set_step }) => {

    const dispatch = useDispatch();

    const error = useSelector(({ profileApp }) => profileApp.listing.error);
    const data = useSelector(({ profileApp }) => profileApp.listing.data);

    const [success_select, set_success_select] = useState(false);
    const [error_select, set_error_select] = useState(false);

    const [per_page, set_per_page] = useState(10);

    const [form, setForm] = useState('');

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(Actions.getCompanyByName({ name: form }));
    };

    const loadMore = () => {
        const new_per_page = per_page + 10;

        dispatch(Actions.getCompanyByName({
            name: form,
            per_page: new_per_page
        }))
            .then(() => set_per_page(new_per_page))
    };

    const validate = () => form.length;

    const selectCompany = ({ company_number }) => {
        set_success_select(false);
        set_error_select(false);

        const field_id = get(find(fields, { name: "Company Number" }), 'id');

        dispatch(Actions.setProfileField({ field_id, value: company_number }))
            .then(() => {
                dispatch(Actions.getUserXprofileData());
                set_success_select(true);

                setTimeout(() => set_step(2), 2000);
            })
            .catch(() => set_error_select(true))
    };

    return (
        <>
            <Col xs={ 6 }>
                <Form
                    onSubmit={ handlerSubmit }
                    inline
                    className="mt-4"
                >
                    <Form.Group controlId="formBasicCompanyName">
                        <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={ form }
                            onChange={ ({ target }) => setForm(target.value) }
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={ !validate() }
                        className="ml-3"
                    >
                        Find
                    </Button>
                </Form>
            </Col>
            <Col xs={ 12 }>
                {
                    error && (
                        <Alert variant="danger" className="mt-4 mb-0">
                            Error find
                        </Alert>
                    )
                }
                {
                    success_select && (
                        <Alert variant="success"
                               className="mt-4 mb-0 d-flex justify-content-between align-items-center">
                            Success selected
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Alert>
                    )
                }
                {
                    error_select && (
                        <Alert variant="danger" className="mt-4 mb-0">
                            Error selected
                        </Alert>
                    )
                }
                {
                    Boolean(data.length) && (
                        <>
                            <ListGroup>
                                {
                                    data.map((el) => (
                                        <ListGroup.Item
                                            className="mt-4 d-flex align-items-center"
                                            key={ el.company_number }
                                        >
                                            <div className="d-flex flex-wrap w-100">
                                                <dt className="w-50">Name</dt>
                                                <dd className="w-50">{ get(el, 'title') }</dd>
                                                <dt className="w-50">Address</dt>
                                                <dd className="w-50">{ get(el, 'address_snippet') }</dd>
                                            </div>
                                            <Button
                                                variant="secondary"
                                                className="ml-3 flex-shrink-0"
                                                onClick={ () => selectCompany({ company_number: el.company_number }) }
                                            >
                                                Select
                                            </Button>
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                            <Button
                                onClick={ loadMore }
                                className="mt-4"
                            >
                                Load more
                            </Button>
                        </>
                    )
                }
            </Col>
        </>
    )
};

export default ProfileListing;
