import React, { useEffect } from "react";
import {Container, Card, Row, Col, Alert} from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from '../store/actions';
import get from 'lodash/get';
import SideMenu from "./SideMenu";
import { Link } from "@reach/router";
import {navigate} from "@reach/router";

const Deals = (props) => {
  const dispatch = useDispatch();

  const { data, error } = useSelector(({ profileApp }) => profileApp.deal);

  useEffect(() => {
    dispatch(Actions.getDeal(props.ID));

    return () => dispatch(Actions.clearDeal());
  }, [ dispatch, props.ID ]);

  return (
      <>
        <div className="container-fluid">
          <Row>
            <SideMenu/>
            <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
              <Container className="py-2 my-0">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/offers">Offers</Link>
                    </li>
                    {
                      data && (
                          <li className="breadcrumb-item active" aria-current="page">
                            {data.title.rendered}
                          </li>
                      )
                    }
                  </ol>
                </nav>
              </Container>
            </section>
            {console.log(data)}
            <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
              <div className="academy-dash__content">
                <div className="container">
                  <Row className="no-gutters d-flex mb-3">
                    <Container>
                      {
                        data && (
                          <Row className="no-gutters d-flex">
                            <Col xs={ 12 }>
                              <h1 className="text-left mb-4 h4" dangerouslySetInnerHTML={ { __html: data.title.rendered } }/>
                            </Col>
                          </Row>
                        )
                      }
                      <Row className="d-flex mb-3">
                        { error && <strong>Error fetch offers</strong> }
                        {
                          data && (
                              <>
                                <Col xs={12}>
                                  <div dangerouslySetInnerHTML={ { __html: data.content.rendered } }/>
                                </Col>
                              </>
                          )
                        }
                      </Row>
                      <Row className="d-flex flex-column">
                        {
                          data && (
                            <>
                              {
                                data.acf.website && (
                                  <Col xs={12}>
                                    <button className="btn btn-primary mb-3" onClick={() => navigate(data.acf.website)}>
                                      Redeem offer Website
                                    </button>
                                  </Col>
                                )
                              }
                              {
                                data.acf.deal_contact && (
                                  <Col xs={12}>
                                    <a href={'mailto:' + data.acf.deal_contact} className="d-block w-100">
                                      Get in touch with {data.acf.deal_contact}.
                                    </a>
                                  </Col>
                                )
                              }
                            </>
                          )
                        }
                      </Row>
                    </Container>
                  </Row>
                </div>
              </div>
            </main>
          </Row>
        </div>
      </>
  )
};

export default withReducer('profileApp', reducer)(Deals);
