import axios from 'axios';
import * as CONSTANTS from '../../../../../constants';

export const CHANGE_PASSWORD_ERROR = '[CHANGE PASSWORD APP] CHANGE PASSWORD ERROR';
export const CHANGE_PASSWORD_SUCCESS = '[CHANGE PASSWORD APP] CHANGE PASSWORD SUCCESS';
export const CLEAR = '[CHANGE PASSWORD APP] CLEAR';

export function changePassword({ password }) {

    return (dispatch, getState) => {
        const { id } = getState().auth.user;

        dispatch(clear());

        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/${ id }`, {
            password
        })
            .then((response) => {
                dispatch({
                    type: CHANGE_PASSWORD_SUCCESS,
                    payload: true
                })
            })
            .catch((error) => {
                dispatch({
                    type: CHANGE_PASSWORD_ERROR,
                    payload: error.response.data.message
                })
            })
    }
}

export function clear() {

    return (dispatch) => dispatch({ type: CLEAR });
}
