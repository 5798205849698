import React, { useEffect, useState } from "react";
import ProfileListing from "./ProfileListing";
import ProfileListingCompanyForm from "./ProfileListingCompanyForm";
import * as Actions from "../store/actions";
import { useDispatch } from "react-redux";

const ProfileListingManage = ({ fields, active_subscriptions }) => {

    const dispatch = useDispatch();

    const [step, set_step] = useState(1);

    useEffect(() => {

        return () => {
            dispatch(Actions.clearCompany())
        }
    }, [dispatch]);

    // if (!active_subscriptions) {
    //     navigate('/dashboard/profile');
    // }

    return (
        <>
            { step === 1 && <ProfileListing fields={ fields } set_step={ set_step }/> }
            {/*{ step === 2 && <ProfileListingCompanyInfo set_step={ set_step } number={ 123412 }/> }*/}
            { step === 2 && <ProfileListingCompanyForm set_step={ set_step }/> }
        </>
    )
};

export default ProfileListingManage;
