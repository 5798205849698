import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import reducer from "./store/reducers";
import withReducer from "../../../store/withReducer";
import {Container, Row, Col, Card} from "react-bootstrap";
import get from "lodash/get";
import SideMenu from "../Profile/components/SideMenu";
import { Link } from "@reach/router";
import PostStatus from "../../../components/PostStatus";
import { is_state } from "../../../constants";
import * as GeneralActions from "../../../store/actions";
import * as CONSTANTS from "../../../constants";

const Lesson = (props) => {
    const dispatch = useDispatch();

    const user_acf = useSelector(({ auth }) => auth.user.acf);
    const data = useSelector(({ lessonApp }) => lessonApp.lesson.data);

    const lesson_complete = is_state(props.ID, user_acf, 'lesson_complete');

    useEffect(() => {
        dispatch(Actions.getLesson({
            id: props.ID
        }));

        return () => {
            dispatch(Actions.clearLesson());
        }
    }, []);

    function title() {
        return {__html: get(data, 'title.rendered')};
    }

    const renderTitle = () => (
      <h3 className="text-left mb-4 h4" dangerouslySetInnerHTML={title()}
          style={{
              textDecoration: lesson_complete ? 'line-through' : '',
          }}
      />
    );


    function videoContent(item) {
        return {__html: get(item, 'short_description')};
    }

    const renderVideoContent = (item) => (
        <p className="d-block w-100 mb-3 mb-md-0" dangerouslySetInnerHTML={videoContent(item)}/>
    );

    const renderVideos = () => (
        data && get(data, 'acf.lesson_videos') && get(data, 'acf.lesson_videos').map((item) => {

            const video_complete = is_state(get(item, 'ID'), user_acf, 'video_complete');

            return (
            <Card className="card d-block w-100 mb-3" key={item['ID']}>
                <Row>
                    <Col xs={12} md={2} lg={3}>
                        <Link to={ `/video/${ get(item, 'ID') }` } className="video-item__image h-100 d-block">
                            <img
                                className="h-100 w-100" style={ { objectFit: 'cover' } }
                                src={ `${ get(item, 'featured_media') ? get(item, 'featured_media') : CONSTANTS.SITE_URL + '/app/uploads/2020/09/ssfs-default-video.jpg' }` }
                                alt=""
                            />
                        </Link>
                    </Col>
                    <Col xs={12} md={10} lg={9} className="py-3 card-body">
                        <Row className="d-flex h-100">
                            <Col xs={12} md={8}>
                                <h4 className="mb-3 h6 text-center text-md-left"
                                    style={{
                                        textDecoration: video_complete ? 'line-through' : '',
                                    }}
                                >
                                    <Link className="text-primary" to={ `/video/${ get(item, 'ID') }` }>
                                        { get(item, 'post_title') }
                                    </Link>
                                </h4>
                                {!video_complete && renderVideoContent(item)}
                            </Col>
                            <Col xs={12} md={4} className="d-flex flex-column align-items-center justify-content-center post_status">
                                <PostStatus
                                    item_id={ item['ID'] }
                                    is_complete_state={ (param) => is_state(param, user_acf, 'video_complete') }
                                    is_skipped_state={ (param) => is_state(param, user_acf, 'video_skipped') }
                                    setStatusVideo={ async (payload) => {
                                        await dispatch(GeneralActions.setStatusVideo({
                                            ...payload,
                                            post_type: 'video'
                                        }));
                                    } }
                                    type="video"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            );
        })
    );

    const renderContent = () => <div dangerouslySetInnerHTML={ { __html: get(data, 'content.rendered') } }/>

    return (
        <>
                <Container fluid>
                    <Row>
                        <SideMenu/>
                        <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                            <Container className="py-2 my-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="/lessons">Lessons</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {data && get(data, 'title.rendered')}
                                        </li>
                                    </ol>
                                </nav>
                            </Container>
                        </section>
                        <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                            <div className="academy-dash__content ">
                                <div className="container">
                                    <div className="d-flex mb-3 justify-content-between">
                                        {data && data.previous && (
                                            <a className="font-weight-bold" href={ `/lesson/${ data.previous }` }>
                                                Previous Session
                                            </a>
                                        )}
                                        {data && data.next && (
                                            <a className="font-weight-bold" href={ `/lesson/${ data.next }` }>
                                                Next Session
                                            </a>
                                        )}
                                    </div>
                                    <Row className="no-gutters d-flex mb-3">
                                        <Col xs={12} md={8}>
                                            { renderTitle() }
                                        </Col>
                                        {data && (
                                        <Col xs={12} md={4} className="d-flex justify-content-center flex-column post_status">
                                            <PostStatus
                                                item_id={ data?.id }
                                                is_complete_state={ (param) => is_state(param, user_acf, 'lesson_complete') }
                                                is_skipped_state ={ (param) => is_state(param, user_acf, 'lesson_skipped') }
                                                setStatusVideo={ async (payload) => {
                                                    await dispatch(GeneralActions.setStatusVideo({
                                                        ...payload,
                                                        post_type: 'lesson'
                                                    }));
                                                } }
                                                type="lesson"
                                                is_skip_show={ false }
                                                is_watch_now={ false }
                                            />
                                        </Col>
                                        )}
                                    </Row>
                                    <Row className="no-gutters d-flex mb-3">
                                        <Col xs={12} md={10}>
                                            { renderContent() }
                                        </Col>
                                    </Row>
                                    {data && get(data, 'acf.lesson_videos') &&
                                        <>
                                            <Row className="no-gutters mb-3">
                                                <h6>Watch session videos</h6>
                                            </Row>
                                            <Row className="no-gutters">
                                                { renderVideos() }
                                            </Row>
                                        </>
                                    }
                                </div>
                            </div>
                        </main>
                    </Row>
                </Container>
        </>
    );
};

export default withReducer('lessonApp', reducer)(Lesson);