import React, { useEffect } from "react";
import {Row, Col, Container, Card, Alert} from "react-bootstrap";
import withReducer from "../../../../store/withReducer";
import reducer from "../store/reducers";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from '../store/actions';
import get from 'lodash/get';
import { navigate } from "@reach/router"
import SideMenu from "./SideMenu";
import { Link } from "@reach/router";
import ProfileLessons from "./ProfileLessons";

const Deals = () => {
  const dispatch = useDispatch();

  const { data, error } = useSelector(({ profileApp }) => profileApp.deals);

  useEffect(() => {
    dispatch(Actions.getDeals());

    return () => dispatch(Actions.clearDeals());
  }, [ dispatch ]);

    function truncate(str, n){
        return (str.length > n) ? str.substr(0, n-1) + '' : str;
    };

  return (
      <>
          <div className="container-fluid">
              <Row>
                  <SideMenu/>
                  <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                      <Container className="py-2 my-0">
                          <nav aria-label="breadcrumb">
                              <ol className="breadcrumb mb-0">
                                  <li className="breadcrumb-item">
                                      <Link to="/">Dashboard</Link>
                                  </li>
                                  <li className="breadcrumb-item active" aria-current="page">
                                      Offers
                                  </li>
                              </ol>
                          </nav>
                      </Container>
                  </section>
                  <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                      <div className="academy-dash__content">
                          <div className="container">
                              <Row className="no-gutters d-flex mb-3">
                                  <Container>
                                      <Row className="no-gutters d-flex">
                                          <Col xs={ 12 }>
                                              <h3 className="text-left mb-4 h4">Offers</h3>
                                          </Col>
                                      </Row>
                                      <Row className="d-flex">
                                          {
                                              error && (
                                                  <Col xs={ 12 }>
                                                      <Alert variant="danger" className="mt-4 mb-0">
                                                          Error deals
                                                      </Alert>
                                                  </Col>
                                              )
                                          }
                                          {
                                              Boolean(data.length) && data.map((item) => (
                                                  <Col className="mb-4" xs={ 12 } xl={4} key={ item.id }>
                                                      <Card className="card-deal text-decoration-none" onClick={() => navigate(`/offer/${item.id}`)}>
                                                          <Card.Header>
                                                              <Card.Title dangerouslySetInnerHTML={ { __html: item.title.rendered } }/>
                                                          </Card.Header>
                                                          <Card.Body>
                                                              {item.acf.category &&
                                                              <div className="card-deal__category">
                                                                  <span className="card-deal__label">Category</span>
                                                                  <button
                                                                      className="card-deal__badge">{item.acf.category}</button>
                                                              </div>
                                                              }
                                                              <div className="card-deal__category">
                                                                  <span className="card-deal__label d-block">Description</span>
                                                                  <p className="card-deal-text" dangerouslySetInnerHTML={ {__html: truncate(item.content.rendered, 200).replace(/(<([^>]+)>)/ig, "") }} />
                                                              </div>
                                                              {

                                                              }
                                                              <div className="card-deal__category">
                                                                  <span className="card-deal__label d-block">Details</span>
                                                                  <div className="card-deal-text" dangerouslySetInnerHTML={ { __html: item.excerpt.rendered } }/>
                                                              </div>
                                                          </Card.Body>
                                                          <Card.Footer>
                                                              <button className="btn btn-primary">Read more</button>
                                                          </Card.Footer>
                                                      </Card>
                                                  </Col>
                                              ))
                                          }
                                          <Col className="mb-4" xs={ 12 } lg={6} xl={4}>
                                              <a className="card card-deal bg-primary text-decoration-none" href="https://startupschoolforseniors.com/submit-an-offer/">
                                                  <Card.Body>
                                                      <Card.Title>
                                                          <span className="text-white lead">Do you have an offer that your fellow students can benefit from?</span>
                                                      </Card.Title>
                                                      <div className="py-2 card-deal-text text-white d-flex align-items-center justify-content-center">
                                                          Please go to our offer submission form and give us the inside scoop. <br/><br/> It can be an offer from your business or any other business that wil benefit our community.<br/><br/> After you send in your offer it will take a couple of days for us to verify it and it will appear on this page!
                                                      </div>
                                                  </Card.Body>
                                                  <Card.Footer>
                                                      <button className="btn btn-outline-white">Add your offer</button>
                                                  </Card.Footer>
                                              </a>
                                          </Col>
                                      </Row>
                                  </Container>
                              </Row>
                          </div>
                      </div>
                  </main>
              </Row>
          </div>
      </>
  )
};

export default withReducer('profileApp', reducer)(Deals);
