import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_USER_XPROFILE_DATA = '[PROFILE APP] GET USER XPROFILE DATA';
export const SET_LOAD_XPROFILE_DATA = '[COMMENTS APP] SET LOAD XPROFILE DATA';
export const GET_USER_MAIN_DATA = '[PROFILE APP] GET USER MAIN DATA';
export const GET_USER_ME = '[PROFILE APP] GET USER ME';
export const UPLOAD_AVATAR_ERROR = '[PROFILE APP] UPLOAD AVATAR ERROR';
export const UPLOAD_AVATAR_SUCCESS = '[PROFILE APP] UPLOAD AVATAR SUCCESS';
export const CLEAR = '[PROFILE APP] CLEAR';
export const GET_COHORT = '[PROFILE APP] GET COHORT';
export const CLEAR_COHORT = '[PROFILE APP] CLEAR';

export function getUserXprofileData({
     group_id = 1,
     load = true}) {

    return (dispatch, getState) => {

        if (load) {
            dispatch(setLoadProfileData(true));
        }

        const { id } = getState().auth.user;

        axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/xprofile/fields?user_id=${ id }&profile_group_id=${ group_id }&fetch_field_data=true&context=edit`)
            .then((res) => {
                dispatch({
                    type: GET_USER_XPROFILE_DATA,
                    payload: res.data
                })
                dispatch(setLoadProfileData(false));
            });
    }
}

export function setProfileField({ field_id, value, group_id }) {

    return (dispatch, getState) => {
        const { id } = getState().auth.user;

        return axios.post(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/xprofile/${ field_id }/data/${ id }`, {
            context: 'edit',
            group_id: group_id,
            value
        })
            .then((res) => {

            })
    }
}

export function getUserMainData() {

    return (dispatch, getState) => {
        const { id } = getState().auth.user;

        axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/members/${ id }`)
            .then((res) => {
                dispatch({
                    type: GET_USER_MAIN_DATA,
                    payload: res.data
                })
            });
    }
}

export function getUserMe() {

    return (dispatch) => {

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/me?skip_cache=1`)
            .then((res) => {
                dispatch({
                    type: GET_USER_ME,
                    payload: {
                        avatar: {
                            meta: res.data.meta,
                            avatar_urls: res.data.avatar_urls
                        },
                        active_subscriptions: res.data.active_subscriptions,
                    }
                })
            });
    }
}

export function uploadAvatar(data) {

    return (dispatch, getState) => {
        dispatch({
            type: UPLOAD_AVATAR_SUCCESS,
            payload: false
        });
        dispatch({
            type: UPLOAD_AVATAR_ERROR,
            payload: false
        });

        axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/media?skip_cache=1`, data)
            .then((res) => {
                const { id } = getState().auth.user;

                axios.post(CONSTANTS.SITE_URL + `/wp-json/wp/v2/users/${ id }?skip_cache=1`, {
                    meta: {
                        avatar: res.data.source_url,
                        avatar_id: res.data.id
                    }
                })
                    .then(() => {
                        dispatch(getUserMe());
                        dispatch({
                            type: UPLOAD_AVATAR_SUCCESS,
                            payload: true
                        })
                    })
                    .catch((res) => {
                        console.log(res);
                        dispatch({
                            type: UPLOAD_AVATAR_ERROR,
                            payload: true
                        })
                    })
            })
            .catch(() => {
                dispatch({
                    type: UPLOAD_AVATAR_ERROR,
                    payload: true
                })
            })
    }
}

export function getCohort() {

    return (dispatch, getState) => {
        // const {acf} = getState().auth.user;
        // const cohorts = acf.cohort_id.length !== 1 ? acf.cohort_id : ''; //Array.isArray(acf.cohort_id)

        axios.get(CONSTANTS.SITE_URL + `/wp-json/wp/v2/cohort/`) //${cohorts}
            .then((res) => {
                dispatch({
                    type: GET_COHORT,
                    payload: res.data
                })
            })
        };

}

export function clearDashboard() {

    return (dispatch) => dispatch({type: CLEAR_COHORT});
}


export function clear() {

    return (dispatch) => dispatch({ type: CLEAR });
}


export function setLoadProfileData(isLoad) {

    return (dispatch) => {
        dispatch({
            type: SET_LOAD_XPROFILE_DATA,
            payload: isLoad
        });
    }
}
