import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_USERS = '[MESSAGES APP] GET USERS';
export const ERROR_USERS = '[MESSAGES APP] ERROR USERS';
export const CLEAR_USERS = '[MESSAGES APP] CLEAR USERS';

export function getUsers({ page = 1, search }) {

    return (dispatch) => {
        dispatch(errorGetUsers(false));

        return axios.get( CONSTANTS.SITE_URL + `/wp-json/wp/v2/users`, {
            params: {
                page,
                search,
                per_page: 5
            }
        })
            .then((response) => {
                dispatch({
                    type: GET_USERS,
                    payload: response.data
                });

                if (!response.data.length) {
                    dispatch(errorGetUsers(true));
                }
            })
            .catch(() => {
                dispatch(errorGetUsers(true));
            })
    }
}

export function sendNewMessage({ message, recipients }) {

    return (dispatch) => {

        return axios.post(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/messages`, {}, {
            params: {
                message,
                recipients,
            }
        })
    }
}

export function clearUsers() {

    return (dispatch) => {
        dispatch({
            type: CLEAR_USERS
        });
    }
}

export function errorGetUsers(isError) {

    return (dispatch) => {
        dispatch({
            type: ERROR_USERS,
            payload: isError
        });
    }
}