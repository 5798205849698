import {useSelector} from "react-redux";
import React from "react";
import { Redirect } from "@reach/router";

const PublicRoute = ({ as: Component, ...props }) => {
    const user = useSelector(({ auth }) => auth.user);
    return (
        <>
            {!user ? <Component {...props}/> : <Redirect to="/dashboard" default noThrow /> }
        </>
    )
}

export default PublicRoute