import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import withReducer from "../../../store/withReducer";
import reducer from "./store/reducers";
import MessagesAside from "./components/MessagesAside";
import DialogAside from "./components/DialogAside";
import { Link } from "@reach/router";

import './style/messages.css';
import SideMenu from "../Profile/components/SideMenu";

const Messages = (props) => {

    const [isListActive, setIsListActive] = useState(true);

    const setListActive = (isActive) => setIsListActive(isActive);

    return (
        <>
            <div className="container-fluid">
                <Row>
                    <SideMenu/>
                    <section className="col-md-12 ml-sm-auto col-lg-9 breadcrumbs-block bg-light border-bottom mb-4">
                        <Container className="py-2 my-0">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Messages
                                    </li>
                                </ol>
                            </nav>
                        </Container>
                    </section>
                    <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                        <div className="academy-dash__content" style={{minHeight: "calc(100vh - 80px)"}}>
                            <div className={`message-inner${isListActive ? ' active' : ''}`}>
                                <div className="message-cards">
                                    <div className="message-card message-card--aside flex-shrink-0">
                                        <MessagesAside loc={props.location.state} setListActive={setListActive}/>
                                    </div>
                                    <div className="message-card ml-3">
                                        <DialogAside setListActive={setListActive}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Row>
            </div>
        </>
    )
};

export default withReducer('messagesApp', reducer)(Messages);