import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions';
import get from "lodash/get";
import moment from 'moment';

import '../style/dialog.css';

const initForm = {
    message: '',
};

const UserDialog = (props) => {
    const dispatch = useDispatch();

    const user = useSelector(({ auth }) => auth.user);
    const data = useSelector(({ messagesApp }) => messagesApp.userDialog.data);
    const messages = useSelector(({ messagesApp }) => messagesApp.userDialog.messages);
    const error = useSelector(({ messagesApp }) => messagesApp.userDialog.error);
    const loadDialog = useSelector(({ messagesApp }) => messagesApp.userDialog.loadDialog);

    const [per_page, set_per_page] = useState(15);
    const [form, setForm] = useState(initForm);

    const messagesRef = useRef(null);

    const scrollToBottom = () => {
        messagesRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
        });
    };

    useEffect(() => {
        // be sure we need to scroll
        if (per_page === 15 && messages.length > 5) {
            scrollToBottom();
        }
    }, [dispatch, messages, per_page]);

    useEffect(() => {
        dispatch(Actions.getUserDialog({
            id: props.active_id
        }));

        return () => {
            dispatch(Actions.clearUserDialog());
        }
    }, [dispatch, props.active_id]);

    useEffect(() => {

        let timerId;

        timerId = setInterval(() => {
            if (props.active_id) {
                getUserMessages();
            }
        }, 3000);

        return () => {
            clearInterval(timerId);
        }
        // eslint-disable-next-line
    }, [dispatch, per_page]);

    const handlerForm = ({ target }) => {
        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const getUserMessages = () => {
        dispatch(Actions.getUserMessages({
            per_page,
        }));
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(Actions.sendMessage({
            message: form.message
        }))
            .then(() => {
                setForm(initForm);

                dispatch(Actions.getUserMessages({
                    per_page,
                    load: true
                }));

                dispatch(Actions.getMessages({ load: true }));

            })
    };

    const validate = () => (
        form.message.length
    );

    const handleScroll = (el) => {

        if (get(el, 'nativeEvent.target.scrollTop') === 0) {

            let new_per_page = per_page + 10;

            dispatch(Actions.getUserMessages({
                per_page: new_per_page,
                load: true
            }))
                .then(() => {
                    set_per_page(new_per_page);
                })
        }
    };

    return (
        <>
            {
                loadDialog && (
                    <div className="d-flex justify-content-center mt-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            {
                error && <div className="message-notif">Error get messages</div>
            }
            {
                Boolean(messages.length) && (
                    <>
                        <div className="dialog__window" id="card_window" onScroll={ handleScroll }>
                            {
                                messages.slice().map((item) => {
                                    const isInterlocutor = item.sender_id !== user.id;
                                    return (
                                        <div className={
                                            `dialog__item ${ isInterlocutor ? ' dialog__item--interlocutor' : '' }`
                                        }
                                             key={ item.id }
                                        >
                                            <div className="dialog__item-message">
                                                { item.message }
                                                <div className="dialog__item-date">
                                                    { moment(item.date_sent).calendar() }
                                                </div>
                                            </div>
                                            <div className="dialog__item-person d-flex flex-column">
                                                <Image
                                                    src={ get(data, `recipients.[${ item.sender_id }].user_avatars.thumb`) }
                                                    width={ 50 }
                                                    height={ 50 }
                                                    roundedCircle
                                                    className="dialog__item-avatar"
                                                />
                                                <span className={`small`}>{ item.sender_name }</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div ref={messagesRef} />
                        </div>
                        <Form
                            onSubmit={ handlerSubmit }
                            className="dialog-form"
                        >
                            <Form.Control
                                type="text"
                                name="message"
                                placeholder="Message"
                                value={ form.message }
                                onChange={ handlerForm }
                                className="w-100"
                            />
                            <Button
                                type="submit"
                                className="ml-3 flex-shrink-0"
                                disabled={ !validate() }
                            >
                                Send
                            </Button>
                        </Form>
                    </>
                )
            }
        </>
    )
};

export default UserDialog;