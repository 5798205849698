import React, { useEffect, useState } from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";
import { Link } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';

const PasswordForgot = (props) => {

    const dispatch = useDispatch();

    const error = useSelector(({ passwordForgotApp }) => passwordForgotApp.passwordForgot.error);
    const success = useSelector(({ passwordForgotApp }) => passwordForgotApp.passwordForgot.success);

    useEffect(() => {

        return () => {
            dispatch(Actions.clear());
        }
    }, [dispatch]);

    const [form, setForm] = useState({
        user_login: '',
    });

    const handlerForm = ({ target }) => {
        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(Actions.sendPasswordForgot({
            user_login: form.user_login,
        }));
    };

    const validate = () => (
        form.user_login.length
    );

    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={12} lg={6} className="my-4 my-md-8">
                    <h3 className="text-center mb-4">Password Forgot</h3>
                    <Form
                        className="PasswordForget"
                        onSubmit={ handlerSubmit }
                    >
                        {
                            error && (
                                <Alert variant="danger">
                                    { error }
                                </Alert>
                            )
                        }
                        {
                            success && (
                                <Alert variant="success">
                                    { success }
                                </Alert>
                            )
                        }
                        <Form.Group controlId="formBasicUserLogin">
                            <Form.Label>User Login</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_login"
                                placeholder="Your email address or username"
                                value={ form.user_login }
                                onFocus={(e) => e.target.placeholder = ""}
                                onBlur={(e) => e.target.placeholder = "Your email address or username"}
                                onChange={ handlerForm }
                            />
                        </Form.Group>
                        <div className="d-flex pt-2">
                            <Button
                                variant="secondary"
                                type="submit"
                                disabled={ !validate() }
                            >
                                Submit
                            </Button>
                            <Link to="/register" className="footer-link ml-auto">Sign up</Link>
                            <Link to="/login" className="footer-link ml-3">Sign in</Link>
                        </div>
                    </Form>
                 </Col>
            </Row>
        </Container>
    )
};

export default withReducer('passwordForgotApp', reducer)(PasswordForgot);