import React, { useEffect, useState } from "react";
import {Spinner, Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";
import { Link } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import axios from "axios";
import * as userActions from "../../store/actions";
import { SvgLogo } from "../../components/svgs"

const Login = (props) => {

    const dispatch = useDispatch();

    const error = useSelector(({ loginApp }) => loginApp.login.error);
    const isLoad = useSelector(({ loginApp }) => loginApp.login.isLoad);

    useEffect(() => {

        return () => {
            dispatch(Actions.clear());
        }
    }, [dispatch]);

    const [form, setForm] = useState({
        username: '',
        password: '',
    });

    const handlerForm = ({ target }) => {
        setForm((oldForm) => ({
            ...oldForm,
            [target.name]: target.value
        }))
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        dispatch(Actions.loginWithParams({
            username: form.username,
            password: form.password,
        }));
    };

    const validate = () => (
        form.username.length
        && form.password.length
    );

    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <Col xs={12} lg={12} className="mt-8 mb-2 d-flex align-items-center justify-content-center">
                    <Link to="/" className="header__logo mr-0 mr-lg-4" title="Home">
                        <span className="sr-only">Startup School for Seniors</span>
                        <SvgLogo/>
                    </Link>
                </Col>
                <Col xs={12} lg={6} className="my-4 my-md-8">
                    <h3 className="text-center mb-4 h5">Login to your account</h3>
                    <Form
                        className="login-form"
                        onSubmit={ handlerSubmit }
                    >
                        {
                            error && (
                                <Alert variant="danger">
                                    <div dangerouslySetInnerHTML={ { __html: error } }/>
                                </Alert>
                            )
                        }
                        <Form.Group controlId="formBasicUsername">
                            <Form.Label>Username or email</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                placeholder="Your username"
                                value={ form.username }
                                onFocus={(e) => e.target.placeholder = ""}
                                onBlur={(e) => e.target.placeholder = "Your username"}
                                onChange={ handlerForm }
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="Your password"
                                value={ form.password }
                                onFocus={(e) => e.target.placeholder = ""}
                                onBlur={(e) => e.target.placeholder = "Your password"}
                                onChange={ handlerForm }
                            />
                        </Form.Group>
                        <div className="d-flex pt-2">
                            <Button
                                variant="secondary"
                                type="submit"
                                disabled={ !validate() }
                            >
                                {
                                    isLoad && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: 5 }}
                                        />
                                    )
                                }
                                Submit
                            </Button>
                            <Link to="/register" className="footer-link ml-3 footer-link ml-auto d-flex align-items-center"><span>Sign up</span></Link>
                            <Link to="/password-forget" className="footer-link ml-3 d-flex align-items-center"><span>Password Forgot</span></Link>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
};

export default withReducer('loginApp', reducer)(Login);