import React, { useEffect } from "react";
import {Col, Container, Row} from "react-bootstrap";
import ProfileInfo from "../../components/ProfileInfo";
import ProfileGroups from "../../components/ProfileGroups";
import withReducer from "../../../store/withReducer";
import reducer from "./store/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from './store/actions';
import SideMenu from "./components/SideMenu";

const Profile = ({ location, data }) => {
    const dispatch = useDispatch();
    const xprofile = useSelector(({ profileApp }) => profileApp.profile.xprofile);
    const main_data = useSelector(({ profileApp }) => profileApp.profile.main_data);
    const avatar = useSelector(({ profileApp }) => profileApp.profile.avatar);
    const upload_error = useSelector(({ profileApp }) => profileApp.profile.upload_error);
    const upload_success = useSelector(({ profileApp }) => profileApp.profile.upload_success)
    const load = useSelector(({ profileApp }) => profileApp.profile.load);

    const { state = {} } = location;
    const { group_id } = state ? state : 1;

    useEffect(() => {
        dispatch(Actions.getUserXprofileData({
            group_id: group_id,
            load: true,
        }));
        dispatch(Actions.getUserMainData());
        dispatch(Actions.getUserMe());
    }, [dispatch, group_id]);

    return (
           <>
               <Container fluid>
                   <Row>
                       <SideMenu/>
                       <main role="main" className="col-12 col-md-12 ml-sm-auto col-lg-9">
                            <div className="academy-dash__content px-lg-2 px-0">
                                   <Row className="no-gutters d-flex">
                                       <Col xs={ 12 } className="card">
                                           {
                                               (main_data && xprofile) && (
                                                   <>
                                                       <div className="card-header bg-transparent px-lg-4 py-lg-3 p-1">
                                                           <ProfileInfo
                                                               avatar={ avatar }
                                                               upload_error={ upload_error }
                                                               upload_success={ upload_success }
                                                               name={ main_data.name }
                                                           />
                                                       </div>
                                                       <div className="card-body px-2 px-lg-4 py-3">
                                                           <Row className="justify-content-md-center">
                                                               <Col>
                                                                   <dl className="profileContent row">

                                                                       <dt className="col-12 col-md-6 mb-3">Mention name</dt>
                                                                       <dd className="col-12 col-md-6 mb-3 mb-lg-0">{ main_data.mention_name }</dd>
                                                                       {load
                                                                       ? <Col xs={ 12 }>Loading...</Col>
                                                                       : (
                                                                           <ProfileGroups
                                                                               fields={ xprofile }
                                                                               group_id={ group_id }
                                                                           />
                                                                       )}
                                                                   </dl>
                                                               </Col>
                                                           </Row>
                                                       </div>
                                                   </>
                                               )
                                           }
                                       </Col>
                                   </Row>
                           </div>
                       </main>
                   </Row>
               </Container>
            </>
    );
};

export default withReducer('profileApp', reducer)(Profile);
