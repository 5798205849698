import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../store/actions';
import MessagesList from "./MessagesList";
import has from 'lodash/has';

import '../style/messages.css';

const MessagesAside = ({ loc, setListActive }) => {
    const dispatch = useDispatch();

    const data = useSelector(({messagesApp}) => messagesApp.messages.data);
    const error = useSelector(({messagesApp}) => messagesApp.messages.error);
    const isLoad = useSelector(({messagesApp}) => messagesApp.messages.isLoad);
    const active_id = useSelector(({messagesApp}) => messagesApp.messages.active_id);
    const per_page = useSelector(({messagesApp}) => messagesApp.messages.per_page);
    const box = useSelector(({messagesApp}) => messagesApp.messages.box);
    const user = useSelector(({auth}) => auth.user);

    useEffect(() => {
        if (has(loc, 'id')) {
            dispatch(Actions.setActiveIdMessage(loc.id));
            setListActive(false);
        }

        return () => {
            dispatch(Actions.clearMessages());
        }
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(Actions.setPerPageMessage(10));
        dispatch(Actions.getMessages());
    }, [box, dispatch]);

    const loadMore = () => {
        dispatch(Actions.setPerPageMessage(per_page + 10));

        dispatch(Actions.getMessages());
    };

    return (
        <div className="message-aside">
            <div className="message-aside__top">
                <Button
                    variant={box === 'inbox' ? 'outline-primary' : 'light'}
                    size="sm"
                    onClick={() => dispatch(Actions.setBoxMessage('inbox'))}
                >
                    Inbox
                </Button>
                <Button
                    variant={box === 'sentbox' ? 'outline-primary' : 'light'}
                    size="sm"
                    className="ml-3"
                    onClick={() => dispatch(Actions.setBoxMessage('sentbox'))}
                >
                    Sent
                </Button>
            </div>
            {
                isLoad ? (
                    <div className="d-flex justify-content-center mt-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            error && <div className="message-notif">Error get messages</div>
                        }
                        {
                            Boolean(data.length) ? (
                                <>
                                    <MessagesList
                                        data={data}
                                        user={user}
                                        active_id={active_id}
                                        setListActive={setListActive}
                                    />
                                    {
                                        data.length >= 10 && (
                                            <Button
                                                onClick={ loadMore }
                                                className="w-100"
                                                size="sm"
                                            >
                                                Load more
                                            </Button>
                                        )
                                    }
                                </>
                            ) : (
                                <div className="message-notif">No messages available</div>
                            )
                        }
                    </>
                )
            }
        </div>
    )
};

export default MessagesAside;