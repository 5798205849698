import axios from 'axios';
import * as CONSTANTS from "../../../../../constants";

export const GET_MEMBERS = '[MEMBERS APP] GET MEMBERS';
export const SET_MEMBERS_MAX = '[MEMBERS APP] SET MEMBERS MAX';
export const SET_MEMBERS_PERPAGE = '[MEMBERS APP] SET MEMBERS PERPAGE';
export const SET_MEMBERS_SEARCH = '[MEMBERS APP] SET MEMBERS SEARCH';
export const ERROR_MEMBERS = '[MEMBERS APP] ERROR MEMBERS';

export function getMembers() {

    return (dispatch, getState) => {
        const { search = '', per_page = 12 } = getState().membersApp.members;
        const { id } = getState().auth.user;

        dispatch(errorMembers(false));

        return axios.get(CONSTANTS.SITE_URL + `/wp-json/buddypress/v1/members`, {
            params: {
                per_page,
                search,
                exclude: [id]
            },
        })
            .then((response) => {
                dispatch({
                    type: GET_MEMBERS,
                    payload: response.data
                });

                dispatch({
                    type: SET_MEMBERS_MAX,
                    payload: response.headers["x-wp-total"]
                });

                if (!response.data.length) {
                    dispatch(errorMembers(true));
                }
            })
            .catch(() => {
                dispatch(errorMembers(true));
            })
    }
}

export function errorMembers(isError) {

    return (dispatch) => {
        dispatch({
            type: ERROR_MEMBERS,
            payload: isError
        });
    }
}

export function perpageMembers(data) {

    return (dispatch) => {
        return dispatch({
            type: SET_MEMBERS_PERPAGE,
            payload: data
        });
    }
}

export function searchMembers(data) {

    return (dispatch) => {
        dispatch({
            type: SET_MEMBERS_SEARCH,
            payload: data
        });
    }
}