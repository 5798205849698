import {useSelector} from "react-redux";
import Login from "./Login/Login";
import React from "react";

const PrivateRoute = ({ as: Component, ...props }) => {
    const user = useSelector(({ auth }) => auth.user);
    return (
        <>
            {user ? <Component {...props}/> : <Login />}
        </>
    )
}

export default PrivateRoute