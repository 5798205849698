import React, { useState } from "react";
import { Button, Card, Col, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions";
import get from "lodash/get";
import map from "lodash/map";

import '../style/comments.css';

const Comment = ({ author_name, comment, id, username, post_id, has_children, children_count }) => {
    const dispatch = useDispatch();

    const [ content, setContent ] = useState(`@${ username } `);
    const [ isReplyOpen, setIsReplyOpen ] = useState(false);
    const [ show, setShow ] = useState(false);

    const user = useSelector(({ auth }) => auth.user);
    const parents = useSelector(({ comments }) => comments.parents);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShowReply = () => {
        dispatch(Actions.getComments({ id: post_id, parent_id: id, isParent: true, load: false }));
    };

    const handlerConfirm = () => {
        dispatch(Actions.sendComment({
            author_name: user.name || '',
            author_email: user.email || '',
            content,
            post: post_id,
            parent: id
        }))
            .then(() => {
                setIsReplyOpen(false);
                setContent(`@${ username } `)
            });

        handleClose();
    };

    const handlerSubmit = (e) => {
        e.preventDefault();

        handleShow();
    };

    return (
        <Col xs={ 12 } className="mt-2">
            <Card body>
                <Card.Subtitle className="mb-2 text-muted">
                    {/*{ author_name }*/}
                    { username }
                </Card.Subtitle>
                <Card.Text
                    className="comment-content"
                    dangerouslySetInnerHTML={ { __html: comment } }
                />
                <div
                    className="cursor-pointer text-secondary"
                    onClick={ () => setIsReplyOpen(param => !param) }
                >
                    reply
                </div>
                {
                    has_children && (
                        <div
                            className="cursor-pointer text-secondary"
                            onClick={ handleShowReply }
                        >
                            show all replies ({ children_count })
                        </div>
                    )
                }
            </Card>
            <div style={ { marginLeft: 50, marginTop: 8 } }>
                {
                    isReplyOpen && (
                        <Card body>
                            <Form onSubmit={ handlerSubmit } className="d-flex align-items-start">
                                <Form.Control
                                    as="textarea"
                                    rows="1"
                                    name="content"
                                    value={ content }
                                    onChange={ ({ target }) => setContent(target.value) }
                                />
                                <Button
                                    variant="primary"
                                    type="submit"
                                    style={ { marginLeft: 10 } }
                                >
                                    Send
                                </Button>
                            </Form>
                        </Card>
                    )
                }
                {
                    map(get(parents, `[${ id }]`), (com) => (
                        <Comment
                            id={ com.id }
                            author_name={ com.author_name }
                            comment={ com.content.rendered }
                            key={ com.id }
                            username={ com.username }
                            post_id={ post_id }
                            has_children={com.has_children}
                            children_count={com.children_count}
                        />
                    ))
                }
            </div>
            <Modal show={ show } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Post a comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to post a comment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        Close
                    </Button>
                    <Button variant="primary" onClick={ handlerConfirm }>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>
    )
};

export default Comment;
